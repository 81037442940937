<template>
  <footer id="contactos" class="bg-gradient-to-t from-gray-950 to-gray-800 p-8">
    <div class="max-w-screen-xl mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 lg:gap-8">
      <!-- Sección Información legal -->
      <div>
        <p class="font-semibold text-yellow-500 mb-2">INFORMACION LEGAL</p>
        <ul class="pl-0">
          <li>
            <a href="https://drive.google.com/file/d/1qYF1mTF41U2I3RdbPrkSI8TcMRb9IAmP/view?usp=sharing" target="_blank" rel="noopener noreferrer" class="text-gray-200 hover:text-yellow-400">
              Contrato
              de transporte de carga
            </a>
          </li>
          <li><a href="https://drive.google.com/file/d/1Vx9h54J7ODqeT8DaoUtgW6W8tQDHxqFb/view?usp=sharing" target="_blank" rel="noopener noreferrer" class="text-gray-200 hover:text-yellow-400">Contrato de transporte de pasajeros</a></li>
          <li>
            <router-link to="/centro-intitucional" class=" text-gray-200 hover:text-yellow-400">
                Institucional
            </router-link>
          </li>
        </ul>
      </div>

      <!-- Sección Portales asociados -->
      <div>
        <p class="font-semibold text-yellow-500 mb-2">LINK INSTITUCIONALES</p>
        <ul class="pl-0">
          <li><a href="https://www.mindef.gob.bo/" target="_blank" rel="noopener noreferrer"
              class="text-gray-200 hover:text-yellow-400">Ministerio de Defensa</a></li>
          <li><a href="https://ejercito.mil.bo/then3wpag/index.php" target="_blank" rel="noopener noreferrer"
              class="text-gray-200 hover:text-yellow-400">Ejército de Bolivia</a></li>
          <li><a href="https://fab.bo/" target="_blank" rel="noopener noreferrer"
              class="text-gray-200 hover:text-yellow-400">Fuerza aérea boliviana</a></li>
          <li><a href="https://www.armada.mil.bo/" target="_blank" rel="noopener noreferrer"
              class="text-gray-200 hover:text-yellow-400">Armada Boliviana</a></li>
          <li><a href="https://tab-bolivia.com/" target="_blank" rel="noopener noreferrer"
              class="text-gray-200 hover:text-yellow-400">TAB</a></li>
          <li><a href="https://snabol.com.bo/" target="_blank" rel="noopener noreferrer"
              class="text-gray-200 hover:text-yellow-400">SNA</a></li>
        </ul>
      </div>

      <div>
        <p class="font-semibold text-yellow-500 mb-2">INTRANET</p>
        <ul class="pl-0">
          <li>
            <a href="https://tamep.bo:2096/" class="text-gray-200" target="_blank" rel="noopener noreferrer">
              <div class="flex gap-2 hover:text-yellow-400">
                <EnvelopeIcon class="h-6 w-6 text-blue-500 hover:text-yellow-400" />
                <p class="mb-0">
                  CORREO COORPORATIVO
                </p>
              </div>
            </a>
          </li>
          <li>
            <a href="https://sisecc.tamep.bo/" class="text-gray-200 hover:text-yellow-400" target="_blank"
              rel="noopener noreferrer">
              <div class="flex gap-2">
                <DocumentArrowUpIcon class="h-6 w-6 text-blue-500 hover:text-yellow-400" />
                SISECC
              </div>
            </a>
          </li>
          <li><a href="https://pruebaform.tamep.bo" class="text-gray-200 hover:text-yellow-400" target="_blank"
              rel="noopener noreferrer">
              <div class="flex gap-2">
                <EnvelopeOpenIcon class="h-6 w-6 text-blue-500 hover:text-yellow-400 " />
                SMS
              </div>
            </a></li>
          <li class="pt-2">
            <p class="font-semibold text-yellow-500 mb-2">CONTACTOS</p>
            <div class="flex gap-3">
              <a href="https://mivuelo.att.gob.bo/" target="_blank" rel="noopener noreferrer">
                <img :src="att" alt="Certificaciones" class="w-24 h-full" />
              </a>
              <router-link to="/">
                <img :src="logotam" alt="Certificaciones" class="w-24 h-auto"/>
              </router-link>
            </div>
          </li>
        </ul>
      </div>
      <!-- Sección Redes Sociales -->
      <div class="">
        <p class="font-semibold text-yellow-500 mb-2">REDES SOCIALES</p>
        <div class="flex space-x-4 mb-3">
          <!-- Iconos de redes sociales -->
          <a href="https://www.facebook.com/tamEPagenciadeviajes" class="text-blue-900" target="_blank"
            rel="noopener noreferrer">
            <img :src="facebookLogo" alt="Facebook" class="w-6 h-6" />
          </a>
          <a href="https://twitter.com" class="text-blue-900 rounded-md overflow-hidden" target="_blank"
            rel="noopener noreferrer">
            <img :src="logox" alt="logox" class="w-6 h-6 bg-white">
          </a>
          <a href="https://youtube.com" class="text-blue-900" target="_blank" rel="noopener noreferrer">
            <img :src="youtubeLogo" alt="YouTube" class="w-6 h-6" />
          </a>
          <a href="https://www.tiktok.com/@tam_ep?_t=8nJjt1UovNV&_r=1" class="text-blue-900" target="_blank"
            rel="noopener noreferrer">
            <img :src="tiktokLogo" alt="TikTok" class="w-6 h-6" target="_blank" rel="noopener noreferrer" />
          </a>
        </div>
        <p class="text-gray-200">Av. Montes esq. José María Serrano N° 734</p>
      </div>
    </div>
  </footer>
</template>

<script>
import { EnvelopeIcon } from '@heroicons/vue/24/solid';
import { DocumentArrowUpIcon } from '@heroicons/vue/24/solid';
import { EnvelopeOpenIcon } from '@heroicons/vue/24/solid';

export default {
  name: "FootPage",
  data() {
    return {
      facebookLogo: require('../assets/imagenes/facebook.png'),
      youtubeLogo: require('../assets/imagenes/youtube.png'),
      tiktokLogo: require('../assets/imagenes/tiktok.png'),
      logotam: require('../assets/imagenes/logotam.png'),
      logox: require('../assets/imagenes/logox.png'),
      att: require('../assets/imagenes/att.jpeg'),
    };
  },
  components: {
    EnvelopeIcon,
    DocumentArrowUpIcon,
    EnvelopeOpenIcon,
  }
};
</script>