<template>
    <div class="flex flex-col py-7 items-center">
        <div class="flex w-full sm:w-[600px] px-3 lg:px-0 lg:w-[800px] overflow-hidden justify-center rounded-2xl">
            <div class="w-[242px] sm:w-[400px] lg:w-[600px]">
                <img src="../assets/ninos/infante.webp" alt="silla"
                    class="h-64 w-full object-cover rounded-l-2xl">
            </div>
            <div class="flex w-[130px] sm:w-[300px] lg:w-[400px] h-64 bg-gradient-to-t from-cyan-800 via-cyan-500 to-cyan-800 justify-center items-center rounded-r-2xl">
                <p class="text-[16px] sm:text-[18px] lg:text-[22px] font-bold text-white text-wrap px-2 sm:px-3 text-center">
                    Vuelos con los niños y bebés
                </p>
            </div>
        </div>
        <div>
            <CardsNecesidades :infoCards="cardsData" :isViajeFamiliar="true" />
        </div>
    </div>
</template>

<script>
import CardsNecesidades from './CardsNecesidades.vue';

export default {
    name: 'ViajeFamiliar',
    components: {
        CardsNecesidades,
    },
    data() {
        return {
            cardsData: [
                {
                    title: "INFANTES",
                    content: `Se considera infante el pasajero que no ha cumplido los dos años de edad hasta la fecha del vuelo. Se permite el embarque de infantes sólo si van acompañados por un pasajero adulto<ul class="list-disc pl-4">
                        <li>Viaja sin ocupar asiento
                        </li>
                        <li>Paga el 10% de la tarifa del adulto 
                        </li>
                        <li>Tiene prioridad en el embarque 
                        </li>
                        <li>Puede llevar carrito de bebe hasta la puerta de la aeronave, con etiqueta correspondiente para ser estibado posteriormente 
                        </li>
                        <li>No deben sentarse en salida de emergencia. 
                        </li>
                        <li>No se acepta más de un infante por adulto. 
                        </li>
                        <li>No se acepta más de un infante por fila. Cada fila de asiento tiene una máscara extra de oxígeno. 
                        </li>
                        <li>Si viaja con solo uno de los padres o con apoderados deberán seguir los requisitos correspondientes.
                        </li>
                        <ul/>`,
                    image: require('../assets/ninos/infantes.webp') // Usando require
                },
                {
                    title: "MENORES DE EDAD - CHD",
                    content: `Se considera menor el pasajero que habiendo cumplido los dos años de edad y no ha cumplido los doce hasta la fecha del vuelo, y que viaja acompañado de una persona mayor de 18 años
                    <ul class="list-disc pl-4">
                        <li>Ocupa asiento. 
                        </li>
                        <li>Paga un porcentaje de la tarifa del adulto.
                        </li>
                        <li>Tiene derecho a franquicia, misma del adulto.
                        </li>
                        <li>No deben sentarse en salidas de emergencia.
                        </li>
                        <li>Si viaja con solo uno de los padres o con apoderados deberán seguir los requisitos correspondientes.
                    <ul/>`,
                    image: require('../assets/ninos/menores.webp') // Usando require
                },
                {
                    title: "MENORES NO ACOMPAÑADOS - UMNR",
                    content: "Se considera al menor entre 5 a 12 años, que viaja sin acompañantes, el cual debe presentar el respectivo permiso de viaje autorizado por la defensoría del menor.",
                    image: require('../assets/ninos/menorNo.webp') // Usando require
                },
                {
                    title: "JOVEN VIAJERO",
                    content: "Se considera joven viajero al pasajero que sea mayor a 12 años y menor de 18 años, sin necesidad de ir en custodia de la línea aérea. Se realizará el procedimiento de menor no acompañado solo en caso de solicitud por parte del padre o tutor, el cual debe presentar el respectivo permiso de viaje autorizado por la defensoría del menor.",
                    image: require('../assets/ninos/jovenViajero.webp') // Usando require
                },
            ]
        }
    }
}
</script>