<template>
  <div class="absolute z-20 shadow-lg py-3 mx-0 w-full bg-white">
    <!-- Layout para Ofertas y destinos -->
    <div v-if="menuType === 'Ofertas y destinos'" class="w-full mx-2 grid grid-cols-1 lg:grid-cols-4 gap-2 max-h-96 overflow-y-auto">
      <!-- Columna 1 para el menú -->
      <div class="col-span-1 pl-10">
        <h3 class="text-3xl lg:text-3xl font-semibold text-blue-800 pb-2">Ofertas y destinos</h3>
        <ul class="space-y-3 pl-0 flex flex-col">
          <li class=" ">
            <a href="#" class="" @click.prevent="navigateTo('/', '#itinerario')">
              <div class="flex justify-between mr-3 text-black hover:text-blue-400">
                Itinerario
                <span class="text-xl">➔</span>
              </div>
            </a>
          </li>
          <li class="">
            <a href="#" class="" @click.prevent="navigateTo('/', '#ofertas')">
              <div class="flex justify-between mr-3 text-black hover:text-blue-400">
                Ofertas de vuelos
                <span class="text-xl">➔</span>
              </div>
            </a>
          </li>
          <li class="">
            <a href="#" class="" @click.prevent="navigateTo('/', '#proximos')">
              <div class="flex justify-between mr-3 text-black hover:text-blue-400">
                Proximos Rutas
                <span class="text-xl">➔</span>
              </div>
            </a>
          </li>
          <li class="">
            <a href="#" class="" @click.prevent="navigateTo('/', '#oficinas')">
              <div class="flex justify-between mr-3 text-black hover:text-blue-400">
                Nuestras Oficinas
                <span class="text-xl">➔</span>
              </div>
            </a>
          </li>
          <li class="">
            <a href="#" class="" @click="navigateTo('/', '#contactos')">
              <div class="flex justify-between mr-3 text-black hover:text-blue-400">
                Contáctanos
                <span class="text-xl">➔</span>
              </div>
            </a>
          </li>
        </ul>
      </div>

      <!-- Carrusel de imágenes para Ofertas y destinos -->
      <div class="col-span-3 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 pr-10">
        <div v-for="(image, index) in visibleImages" :key="index" class="relative">
          <img :src="image" alt="Imagen" class="w-full h-full object-contain lg:object-fill rounded-lg transition-transform duration-400 ease-in-out">
        </div>
      </div>
    </div>

    <!-- Layout de dos columnas para Reservar, Tu reserva, y Información y ayuda -->
    <div v-else class="md:w-auto lg:container sm:mx-4 lg:mx-9 grid grid-cols-2 max-h-96 overflow-y-auto">
      <!-- Columna 1: Opciones del menú -->
      <div class="col-span-3 sm:col-span-1 w-10/12 lg:w-3/4 pl-0">
        <ul class="space-y-2 pl-4 mb-0">
          <h3 class="text-2xl lg:text-3xl font-semibold text-blue-800 pb-1">Información y ayuda</h3>
          <!-- Opciones para Reservar -->
          <li v-if="menuType === 'Reservar'"><a href="#" class=" text-black hover:text-blue-400" @click="selectOption('Reservar ahora')">Reservar ahora</a></li>
          <!-- Opciones para Tu reserva -->
          <li v-if="menuType === 'Tu reserva'"><a href="#" class=" text-black hover:text-blue-400" @click="selectOption('Ver mi reserva')">Ver mi reserva</a></li>
          <!-- Opciones para Información y ayuda -->
          <li v-if="menuType === 'Información y ayuda'">
            <router-link to="/equipaje" @click="closeDropdown" class=" text-black hover:text-blue-400">
              <div class="flex justify-between mr-3 text-black hover:text-blue-400">
                Equipaje
                <span class="text-xl">➔</span>
              </div>
            </router-link>
          </li>
          <li v-if="menuType === 'Información y ayuda'">
            <router-link to="/requisitos-viajar" @click="closeDropdown" class=" text-black hover:text-blue-400">
              <div class="flex justify-between mr-3 text-black hover:text-blue-400">
                Requisitos para viajar
                <span class="text-xl">➔</span>
              </div>
            </router-link>
          </li>
          <li v-if="menuType === 'Información y ayuda'">
            <router-link to="/necesidades-especiales" @click="closeDropdown" class=" text-black hover:text-blue-400">
              <div class="flex justify-between mr-3 text-black hover:text-blue-400">
                Necesidades especiales
                <span class="text-xl">➔</span>
              </div>
            </router-link>
          </li>
          <li v-if="menuType === 'Información y ayuda'">
            <router-link to="/vuelos-ninos-bebes" @click="closeDropdown" class=" text-black hover:text-blue-400">
              <div class="flex justify-between mr-3 text-black hover:text-blue-400">
                Vuelos con los niños y bebés
                <span class="text-xl">➔</span>
              </div>
            </router-link>
          </li>
          <li v-if="menuType === 'Información y ayuda'">
            <router-link to="/mascotas-bordo" @click="closeDropdown" class=" text-black hover:text-blue-400">
              <div class="flex justify-between mr-3 text-black hover:text-blue-400">
                Mascotas a bordo
                <span class="text-xl">➔</span>
              </div>
            </router-link>
          </li>
          <li v-if="menuType === 'Información y ayuda'">
            <router-link to="/centro-ayuda" class=" text-black hover:text-blue-400" @click="closeDropdown">
              <div class="flex justify-between mr-3 text-black hover:text-blue-400">
                Centro de ayuda
                <span class="text-xl">➔</span>
              </div>
            </router-link>
          </li>
        </ul>
      </div>

      <!-- Columna 2: Carrusel de imágenes -->
      <div class="lg:w-auto col-span-2 flex sm:col-span-1 lg:col-span-1 relative ml-5 lg:ml-0">
        <img :src="visibleImage" alt="Imagen Carrusel" class="w-2/3 object-cover lg:w-7/12 h-64 lg:object-cover rounded-l-2xl transition-transform duration-400 ease-in-out">
        <div class="lg:w-full flex col-span-2 bg-gradient-to-t from-cyan-800 via-cyan-500 to-cyan-800 items-center justify-center rounded-r-2xl">
          <p class="text-white text-[15px] lg:text-xl font-bold text-center">¿Necesitas ayuda?</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    menuType: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      images: [
        require('@/assets/ofertas/la-paz-1.webp'),
        require('@/assets/ofertas/la-paz-2.webp'),
        require('@/assets/ofertas/la-paz-3.webp'),
        require('@/assets/ofertas/santa-cruz-1.webp'),
        require('@/assets/ofertas/santa-cruz-2.webp'),
        require('@/assets/ofertas/cobija-1.webp'),
        require('@/assets/ofertas/cobija-2.webp'),
        require('@/assets/ofertas/cobija-3.webp'),
        require('@/assets/ofertas/24.webp'),
        require('@/assets/ofertas/25.webp'),
        require('@/assets/ofertas/26.webp'),
      ],
      carousels: {
        Reservar: [],
        'Tu reserva': [],
        'Información y ayuda': [require('@/assets/info-ayuda.webp')],
      },
      currentImageIndex: 0,
      intervalId: null, // Almacenar el ID del intervalo
    };
  },
  computed: {
    visibleImages() {
      if (this.menuType === 'Ofertas y destinos') {
        // Mostrar tres imágenes consecutivas en un bucle
        return [
          this.images[this.currentImageIndex],
          this.images[(this.currentImageIndex + 1) % this.images.length],
          this.images[(this.currentImageIndex + 2) % this.images.length]
        ];
      }
      return [];
    },
    visibleImage() {
      return this.carousels[this.menuType]
        ? this.carousels[this.menuType][this.currentImageIndex]
        : null;
    }
  },
  methods: {
    startCarousel() {
      clearInterval(this.intervalId); // Limpiar cualquier intervalo anterior

      if (this.menuType === 'Ofertas y destinos') {
        this.intervalId = setInterval(() => {
          // Incrementar el índice en 3, con bucle de vuelta al inicio si es necesario
          this.currentImageIndex = (this.currentImageIndex + 3) % this.images.length;
        }, 5000);
      } else if (this.carousels[this.menuType]) {
        this.intervalId = setInterval(() => {
          const carouselLength = this.carousels[this.menuType].length;
          this.currentImageIndex = (this.currentImageIndex + 1) % carouselLength;
        }, 5000);
      }
    },
    selectOption(option) {
      this.$emit('option-selected', option);
      this.closeDropdown(); // Cerrar el dropdown después de seleccionar una opción
    },
    navigateTo(path, hash = '') {
      this.$router.push({ path, hash }).catch(() => {});
      this.closeDropdown();
    },
    closeDropdown() {
      this.$emit('close-dropdown');
    },
  },
  watch: {
    menuType() {
      this.currentImageIndex = 0; // Reiniciar el índice
      this.startCarousel(); // Iniciar el carrusel de nuevo
    }
  },
  mounted() {
    this.startCarousel(); // Iniciar carrusel cuando se monte el componente
  },
  beforeUnmount() {
    clearInterval(this.intervalId); // Limpiar el intervalo cuando se destruya el componente
  }
};
</script>