<template>
  <div class="flex flex-col py-7 items-center">
    <!-- Tarjeta principal -->
    <div class="flex w-full sm:w-[600px] px-3 lg:px-0 lg:w-[800px] overflow-hidden justify-center rounded-2xl">
      <div class="w-[242px] sm:w-[400px] lg:w-[600px]">
        <img src="../assets/intitucional/intutucional.webp" alt="silla" class="h-64 w-full object-cover rounded-l-2xl">
      </div>
      <div class="flex w-[130px] sm:w-[300px] lg:w-[400px] h-64 bg-gradient-to-t from-cyan-800 via-cyan-500 to-cyan-800 justify-center items-center rounded-r-2xl">
        <p class="text-[16px] sm:text-[18px] lg:text-[22px] font-bold text-white text-wrap px-3 sm:px-0 text-center">
          INSTITUCIONAL
        </p>
      </div>
    </div>
    <!-- Tarjetas de opciones -->
    <div class="w-full"> <!-- Añadido w-full-->
      <CardsNecesidades :infoCards="cardsData" :isInstitucional="true" @option-clicked="updateInfoSection" />
    </div>

    <!-- Información de la opción seleccionada -->
    <div ref="infoSection" class="w-full px-6 py-0 mt-3">
      <p v-if="selectedOption" class="text-xl text-white text-center font-bold">{{ selectedOption.title }}</p>
      <div v-if="selectedOption && selectedOption.component">
        <component 
          :is="selectedOption.component" 
          v-bind="selectedOption.componentProps" 
        />
      </div>
      <p v-else class="text-xl text-center text-gray-500">Seleccione una opción para ver detalles.</p>
    </div>
  </div>
</template>

<script>
import CardsNecesidades from './CardsNecesidades.vue';
import DynamicContent from './DynamicContent.vue';
import MisionVison from './MisionVison.vue';
import {
  DocumentTextIcon,
  UserGroupIcon,
  DocumentArrowUpIcon,
} from '@heroicons/vue/24/solid'

export default {
  name: 'InstiTucional',
  components: {
    CardsNecesidades,
    DynamicContent,
  },
  data() {
    return {
      cardsData: [
        {
          title: "Somos TAMEP",
          image: require('../assets/intitucional/tamep.webp'),
          options: [
            {
              title: 'MISION Y VISION',
              component: DynamicContent,
              componentProps: {
                content: MisionVison 
              }
            },
            {
              title: 'RE-SABS de EPTAM',
              component: DynamicContent,
              componentProps: {
                title: 'DIRECCIÓN-croquis',
                content: `
                <div class="flex justify-center">
                  <a href="https://drive.google.com/file/d/1SsmuAcOJW9tFRHeeHkeg0FK5swlp7-IT/view?usp=sharing" target="_blank" rel="noopener noreferrer">
                    <div class="flex gap-3 items-center text-white">
                      <img src="https://img.icons8.com/?size=100&id=13417&format=png&color=000000 " alt="ícono" class="w-12 h-12 md:w-12 md:h-12 lg:w-16 lg:h-16" />
                      RE-SABS de EPTAM
                     </div>
                    </a>
                </div>
                `,
                icon: UserGroupIcon,
                iconClass: 'text-blue-500',
                iconSize: 8
              }
            },
          ]
        },
        {
          title: "RR HH",
          image: require('../assets/intitucional/recursoshumanos.webp'),
          options: [
          {
              title: 'ORGANIGRAMA EPTAM 2024',
              component: DynamicContent,
              componentProps: {
                title: 'ORGANIGRAMA EPTAM 2024',
                content: `
                <div class="flex justify-center">
                  <a href="https://drive.google.com/file/d/1wqQrmMGFzBOLrNOp6vDxwamTpPb0Vw4x/view?usp=sharing" target="_blank" rel="noopener noreferrer">
                    <div class="flex gap-3 items-center text-white">
                      <img src="https://img.icons8.com/?size=100&id=13417&format=png&color=000000 " alt="ícono" class="w-12 h-12 md:w-12 md:h-12 lg:w-16 lg:h-16" />
                      ORGANIGRAMA EPTAM 2024
                     </div>
                    </a>
                </div>
                `,
                icon: UserGroupIcon,
                iconClass: 'text-blue-500',
                iconSize: 8
              }
            },
            {
              title: 'PERSONAL ACTUALIZADO 2024',
              component: DynamicContent,
              componentProps: {
                title: 'PERSONAL ACTUALIZADO 2024',
                content: `
                <div class="flex justify-center">
                  <a href="https://drive.google.com/file/d/1GGyz9Xfpx48C64z2lNxBf3mFT4dT1IM2/view?usp=sharing" target="_blank" rel="noopener noreferrer">
                    <div class="flex gap-3 items-center text-white">
                      <img src="https://img.icons8.com/?size=100&id=13417&format=png&color=000000 " alt="ícono" class="w-12 h-12 md:w-12 md:h-12 lg:w-16 lg:h-16" />
                      PERSONAL ACTUALIZADO 2024
                     </div>
                    </a>
                </div>
                `,
                icon: UserGroupIcon,
                iconClass: 'text-blue-500',
                iconSize: 8
              }
            },
            {
              title: 'MOF CON ENMIENDA 2022',
              component: DynamicContent,
              componentProps: {
                title: 'MOF CON ENMIENDA 2022',
                content: `
                <div class="flex justify-center">
                  <a href="https://drive.google.com/file/d/1sGa4gw6QLK7PNDZ-5dq7ibv3Dj-t8_MH/view?usp=sharing" target="_blank" rel="noopener noreferrer">
                    <div class="flex gap-3 items-center text-white">
                      <img src="https://img.icons8.com/?size=100&id=13417&format=png&color=000000 " alt="ícono" class="w-12 h-12 md:w-12 md:h-12 lg:w-16 lg:h-16" />
                      MOF CON ENMIENDA 2022
                     </div>
                    </a>
                </div>
                `,
                icon: UserGroupIcon,
                iconClass: 'text-blue-500',
                iconSize: 8
              }
            },
            {
              title: 'Nomina Autoridades-ACTUALIZADO 2024',
              component: DynamicContent,
              componentProps: {
                title: 'Nomina Autoridades-ACTUALIZADO 2024',
                content: `
                <div class="flex justify-center">
              <h1 class="text-white text-xl font-bold">Cnl. DAEN. Saravia Burgos René Daniel</h1>
              
              </div>
                `,
                icon: UserGroupIcon,
                iconClass: 'text-blue-500',
                iconSize: 8
              }
            },
          ]
        },
        {
          title: "Gestion Planificación",
          image: require('../assets/intitucional/gestionplan.webp'),
          options: [
          {
              title: 'INFORME SEGUIMIENTO POA 2023-2024',
              component: DynamicContent,
              componentProps: {
                title: 'INFORME SEGUIMIENTO POA 2023-2024',
                content: `
                <div class="flex justify-center">
                  <a href="https://drive.google.com/file/d/1CqxDsyBB0MN1lbh6HtYtnTQ2jeuH1IL1/view?usp=sharing" target="_blank" rel="noopener noreferrer">
                    <div class="flex gap-3 items-center text-white">
                      <img src="https://img.icons8.com/?size=100&id=13417&format=png&color=000000 " alt="ícono" class="w-12 h-12 md:w-12 md:h-12 lg:w-16 lg:h-16" />
                      CROQUIS
                     </div>
                    </a>
                </div>
                `,
                icon: UserGroupIcon,
                iconClass: 'text-blue-500',
                iconSize: 8
              }
            },
            {
              title: 'PEE 2021-2025 CON RD 03.2022',
              component: DynamicContent,
              componentProps: {
                title: 'PEE 2021-2025 CON RD 03.2022',
                content: `
                <div class="flex justify-center">
                  <a href="https://drive.google.com/file/d/1DCGoSEWu2H2DCOPYqY3wgNl1_HZkwEkM/view?usp=sharing" target="_blank" rel="noopener noreferrer">
                    <div class="flex gap-3 items-center text-white">
                      <img src="https://img.icons8.com/?size=100&id=13417&format=png&color=000000 " alt="ícono" class="w-12 h-12 md:w-12 md:h-12 lg:w-16 lg:h-16" />
                      PEE 2021-2025 CON RD 03.2022
                     </div>
                    </a>
                </div>
                `,
                icon: UserGroupIcon,
                iconClass: 'text-blue-500',
                iconSize: 8
              }
            },
            {
              title: 'POA 2023 OFICIAL',
              component: DynamicContent,
              componentProps: {
                title: 'POA 2023 OFICIAL',
                content: `
                <div class="flex justify-center">
                  <a href="https://drive.google.com/file/d/1gr-e662Wst0lmjiUFyRIY0RLKq7jnqrE/view?usp=sharing" target="_blank" rel="noopener noreferrer">
                    <div class="flex gap-3 items-center text-white">
                      <img src="https://img.icons8.com/?size=100&id=13417&format=png&color=000000 " alt="ícono" class="w-12 h-12 md:w-12 md:h-12 lg:w-16 lg:h-16" />
                      POA 2023 OFICIAL
                     </div>
                    </a>
                </div>
                `,
                icon: UserGroupIcon,
                iconClass: 'text-blue-500',
                iconSize: 8
              }
            },
            {
              title: 'POA 2024 CON RD. 01-2023',
              component: DynamicContent,
              componentProps: {
                title: 'POA 2024 CON RD. 01-2023',
                content: `
                <div class="flex justify-center">
                  <a href="https://drive.google.com/file/d/18YkLueIl_bw4WEuF9zFrqaHZj1YX78IT/view?usp=sharing" target="_blank" rel="noopener noreferrer">
                    <div class="flex gap-3 items-center text-white">
                      <img src="https://img.icons8.com/?size=100&id=13417&format=png&color=000000 " alt="ícono" class="w-12 h-12 md:w-12 md:h-12 lg:w-16 lg:h-16" />
                      POA 2024 CON RD. 01-2023
                     </div>
                    </a>
                </div>
                `,
                icon: UserGroupIcon,
                iconClass: 'text-blue-500',
                iconSize: 8
              }
            },
          ]
        },
        {
          title: "Informacion Financiera",
          image: require('../assets/intitucional/financiera.webp'),
          options: [
          {
              title: 'ejecucion presupuestaria hasta 31-05-2024',
              component: DynamicContent,
              componentProps: {
                title: 'ejecucion presupuestaria hasta 31-05-2024',
                content: `
                <div class="flex justify-center">
                  <a href="https://drive.google.com/file/d/1izTUvyk_uveirLlOm_gEVNN6fWaouzFT/view?usp=sharing" target="_blank" rel="noopener noreferrer">
                    <div class="flex gap-3 items-center text-white">
                      <img src="https://img.icons8.com/?size=100&id=13417&format=png&color=000000 " alt="ícono" class="w-12 h-12 md:w-12 md:h-12 lg:w-16 lg:h-16" />
                      ejecucion presupuestaria hasta 31-05-2024
                     </div>
                    </a>
                </div>
                `,
                icon: UserGroupIcon,
                iconClass: 'text-blue-500',
                iconSize: 8
              }
            },
            {
              title: 'fuentes de financiamiento',
              component: DynamicContent,
              componentProps: {
                title: 'fuentes de financiamiento',
                content: `
                <div class="flex justify-center">
                  <a href="https://drive.google.com/file/d/1AV4LCFF4MQ_rqg_2S2guDHfSWg60WMel/view?usp=sharing" target="_blank" rel="noopener noreferrer">
                    <div class="flex gap-3 items-center text-white">
                      <img src="https://img.icons8.com/?size=100&id=13417&format=png&color=000000 " alt="ícono" class="w-12 h-12 md:w-12 md:h-12 lg:w-16 lg:h-16" />
                      fuentes de financiamiento
                     </div>
                    </a>
                </div>
                `,
                icon: UserGroupIcon,
                iconClass: 'text-blue-500',
                iconSize: 8
              }
            },
            {
              title: 'LISTA DE PROVEEDORES EXT-NAL',
              component: DynamicContent,
              componentProps: {
                title: 'LISTA DE PROVEEDORES EXT-NAL',
                content: `
                <div class="flex justify-center">
                  <a href="https://drive.google.com/file/d/1to3bmbZyZOpJz_7cb874MMDwOBAMCteN/view?usp=sharing" target="_blank" rel="noopener noreferrer">
                    <div class="flex gap-3 items-center text-white">
                      <img src="https://img.icons8.com/?size=100&id=13417&format=png&color=000000 " alt="ícono" class="w-12 h-12 md:w-12 md:h-12 lg:w-16 lg:h-16" />
                      LISTA DE PROVEEDORES EXT-NAL
                     </div>
                    </a>
                </div>
                `,
                icon: UserGroupIcon,
                iconClass: 'text-blue-500',
                iconSize: 8
              }
            },
            {
              title: 'LISTA DE PROVEEDORES EXTRANJEROS',
              component: DynamicContent,
              componentProps: {
                title: 'LISTA DE PROVEEDORES EXTRANJEROS',
                content: `
                <div class="flex justify-center">
                  <a href="https://drive.google.com/file/d/1k2cNj0Sp6zttZgrJGCl-CouTCyyubAze/view?usp=sharing" target="_blank" rel="noopener noreferrer">
                    <div class="flex gap-3 items-center text-white">
                      <img src="https://img.icons8.com/?size=100&id=13417&format=png&color=000000 " alt="ícono" class="w-12 h-12 md:w-12 md:h-12 lg:w-16 lg:h-16" />
                      LISTA DE PROVEEDORES EXTRANJEROS
                     </div>
                    </a>
                </div>
                `,
                icon: UserGroupIcon,
                iconClass: 'text-blue-500',
                iconSize: 8
              }
            },
            {
              title: 'LISTA DE PROVEEDORES NACIONALES',
              component: DynamicContent,
              componentProps: {
                title: 'LISTA DE PROVEEDORES NACIONALES',
                content: `
                <div class="flex justify-center">
                  <a href="https://drive.google.com/file/d/1MN7x_RwErnSG554lKOOrbTFJDYH_6Tmp/view?usp=sharing" target="_blank" rel="noopener noreferrer">
                    <div class="flex gap-3 items-center text-white">
                      <img src="https://img.icons8.com/?size=100&id=13417&format=png&color=000000 " alt="ícono" class="w-12 h-12 md:w-12 md:h-12 lg:w-16 lg:h-16" />
                      LISTA DE PROVEEDORES NACIONALES
                     </div>
                    </a>
                </div>
                `,
                icon: UserGroupIcon,
                iconClass: 'text-blue-500',
                iconSize: 8
              }
            },
            {
              title: 'presupuesto institucional',
              component: DynamicContent,
              componentProps: {
                title: 'presupuesto institucional',
                content: `
                <div class="flex justify-center">
                  <a href="https://drive.google.com/file/d/1Dr5U8Uib9WkHmWkLG1oyc1qMsbKgwr2g/view?usp=sharing" target="_blank" rel="noopener noreferrer">
                    <div class="flex gap-3 items-center text-white">
                      <img src="https://img.icons8.com/?size=100&id=13417&format=png&color=000000 " alt="ícono" class="w-12 h-12 md:w-12 md:h-12 lg:w-16 lg:h-16" />
                      Presupuesto institucional
                     </div>
                    </a>
                </div>
                `,
                icon: UserGroupIcon,
                iconClass: 'text-blue-500',
                iconSize: 8
              }
            },
            {
              title: 'SICOES FORM 100',
              component: DynamicContent,
              componentProps: {
                title: 'SICOES FORM 100',
                content: `
                <div class="flex justify-center">
                  <a href="https://drive.google.com/file/d/139gyx67toN4uMsmM1tZnyca0bLzz084t/view?usp=sharing" target="_blank" rel="noopener noreferrer">
                    <div class="flex gap-3 items-center text-white">
                      <img src="https://img.icons8.com/?size=100&id=13417&format=png&color=000000 " alt="ícono" class="w-12 h-12 md:w-12 md:h-12 lg:w-16 lg:h-16" />
                      SICOES FORM 100
                     </div>
                    </a>
                </div>
                `,
                icon: UserGroupIcon,
                iconClass: 'text-blue-500',
                iconSize: 8
              }
            },
            {
              title: 'SICOES FORM 170',
              component: DynamicContent,
              componentProps: {
                title: 'SICOES FORM 170',
                content: `
                <div class="flex justify-center">
                  <a href="https://drive.google.com/file/d/1JCzArYqIlPK5ZJku2yExot0POw_4WLRG/view?usp=sharing" target="_blank" rel="noopener noreferrer">
                    <div class="flex gap-3 items-center text-white">
                      <img src="https://img.icons8.com/?size=100&id=13417&format=png&color=000000 " alt="ícono" class="w-12 h-12 md:w-12 md:h-12 lg:w-16 lg:h-16" />
                      SICOES FORM 170
                     </div>
                    </a>
                </div>
                `,
                icon: UserGroupIcon,
                iconClass: 'text-blue-500',
                iconSize: 8
              }
            },
          ]
        },
        {
          title: "Marco Normativo",
          image: require('../assets/intitucional/marconormativo.webp'),
          options: [
          {
              title: 'RE-SPO de EPTAM',
              component: DynamicContent,
              componentProps: {
                title: 'RE-SPO de EPTAM',
                content: `
                <div class="flex justify-center">
                  <a href="https://drive.google.com/file/d/1zuBdb_so9Nd9XqmmwiqnJNXT1bGoadTN/view?usp=sharing" target="_blank" rel="noopener noreferrer">
                    <div class="flex gap-3 items-center text-white">
                      <img src="https://img.icons8.com/?size=100&id=13417&format=png&color=000000 " alt="ícono" class="w-12 h-12 md:w-12 md:h-12 lg:w-16 lg:h-16" />
                      RE-SPO de EPTAM
                     </div>
                    </a>
                </div>
                `,
                icon: UserGroupIcon,
                iconClass: 'text-blue-500',
                iconSize: 8
              }
            },
            {
              title: 'RE-SOA de EPTAM',
              component: DynamicContent,
              componentProps: {
                title: 'RE-SOA de EPTAM',
                content: `
                <div class="flex justify-center">
                  <a href="https://drive.google.com/file/d/1gr-e662Wst0lmjiUFyRIY0RLKq7jnqrE/view?usp=sharing" target="_blank" rel="noopener noreferrer">
                    <div class="flex gap-3 items-center text-white">
                      <img src="https://img.icons8.com/?size=100&id=13417&format=png&color=000000 " alt="ícono" class="w-12 h-12 md:w-12 md:h-12 lg:w-16 lg:h-16" />
                      RE-SOA de EPTAM
                     </div>
                    </a>
                </div>
                `,
                icon: UserGroupIcon,
                iconClass: 'text-blue-500',
                iconSize: 8
              }
            },
            {
              title: 'RE-SP de EPTAM',
              component: DynamicContent,
              componentProps: {
                title: 'RE-SP de EPTAM',
                content: `
                <div class="flex justify-center">
                  <a href="https://drive.google.com/file/d/1o7Zbcev7nOG90ONvH7j_kcYXDz2shJha/view?usp=sharing" target="_blank" rel="noopener noreferrer">
                    <div class="flex gap-3 items-center text-white">
                      <img src="https://img.icons8.com/?size=100&id=13417&format=png&color=000000 " alt="ícono" class="w-12 h-12 md:w-12 md:h-12 lg:w-16 lg:h-16" />
                      CROQUIS
                     </div>
                    </a>
                </div>
                `,
                icon: UserGroupIcon,
                iconClass: 'text-blue-500',
                iconSize: 8
              }
            },
            {
              title: 'RE-SAP ANEXOS de EPTAM',
              component: DynamicContent,
              componentProps: {
                title: 'RE-SAP ANEXOS de EPTAM',
                content: `
                <div class="flex justify-center">
                  <a href="https://drive.google.com/file/d/1WAad6eWY10Kd_ua46X2naq3sLBhJmh1R/view?usp=sharing" target="_blank" rel="noopener noreferrer">
                    <div class="flex gap-3 items-center text-white">
                      <img src="https://img.icons8.com/?size=100&id=13417&format=png&color=000000 " alt="ícono" class="w-12 h-12 md:w-12 md:h-12 lg:w-16 lg:h-16" />
                      RE-SAP ANEXOS de EPTAM
                     </div>
                    </a>
                </div>
                `,
                icon: UserGroupIcon,
                iconClass: 'text-blue-500',
                iconSize: 8
              }
            },
            {
              title: 'RE-SABS de EPTAM',
              component: DynamicContent,
              componentProps: {
                title: 'RE-SABS de EPTAM',
                content: 'Contenido de Opción pdf',
                icon: DocumentArrowUpIcon,
                iconClass: 'text-red-500',
                iconSize: 8
              }
            },
            {
              title: 'RE-SAP de EPTAM',
              component: DynamicContent,
              componentProps: {
                title: 'RE-SAP de EPTAM',
                content: `
                <div class="flex justify-center">
                  <a href="https://drive.google.com/file/d/1TIcR8Vof4VOxqFaFA_mSkHfTxXwrmubA/view?usp=sharing" target="_blank" rel="noopener noreferrer">
                    <div class="flex gap-3 items-center text-white">
                      <img src="https://img.icons8.com/?size=100&id=13417&format=png&color=000000 " alt="ícono" class="w-12 h-12 md:w-12 md:h-12 lg:w-16 lg:h-16" />
                      RE-SAP de EPTAM
                     </div>
                    </a>
                </div>
                `,
                icon: UserGroupIcon,
                iconClass: 'text-blue-500',
                iconSize: 8
              }
            },
            {
              title: 'DIRECCIÓN-croquis',
              component: DynamicContent,
              componentProps: {
                title: 'RE-SABS de EPTAM',
                content: `
                <div class="flex justify-center">
                  <a href="https://drive.google.com/file/d/1gr-e662Wst0lmjiUFyRIY0RLKq7jnqrE/view?usp=sharing" target="_blank" rel="noopener noreferrer">
                    <div class="flex gap-3 items-center text-white">
                      <img src="https://img.icons8.com/?size=100&id=13417&format=png&color=000000 " alt="ícono" class="w-12 h-12 md:w-12 md:h-12 lg:w-16 lg:h-16" />
                      CROQUIS
                     </div>
                    </a>
                </div>
                `,
                icon: UserGroupIcon,
                iconClass: 'text-blue-500',
                iconSize: 8
              }
            },
            {
              title: 'RE-ST de EPTAM',
              component: DynamicContent,
              componentProps: {
                title: 'RE-ST de EPTAM',
                content: `
                <div class="flex justify-center">
                  <a href="https://drive.google.com/file/d/1iSb2QlBowd9dl7VSoB-fTtIIL5xXQ6uV/view?usp=sharing" target="_blank" rel="noopener noreferrer">
                    <div class="flex gap-3 items-center text-white">
                      <img src="https://img.icons8.com/?size=100&id=13417&format=png&color=000000 " alt="ícono" class="w-12 h-12 md:w-12 md:h-12 lg:w-16 lg:h-16" />
                      RE-ST de EPTAM
                     </div>
                    </a>
                </div>
                `,
                icon: UserGroupIcon,
                iconClass: 'text-blue-500',
                iconSize: 8
              }
            },
            {
              title: 'RE-SCI de EPTAM',
              component: DynamicContent,
              componentProps: {
                title: 'RE-SCI de EPTAM',
                content: `
                <div class="flex justify-center">
                  <a href="https://drive.google.com/file/d/1ujAhFq9RaO7ofRHFWtraw3cp8hLUfjIH/view?usp=sharing" target="_blank" rel="noopener noreferrer">
                    <div class="flex gap-3 items-center text-white">
                      <img src="https://img.icons8.com/?size=100&id=13417&format=png&color=000000 " alt="ícono" class="w-12 h-12 md:w-12 md:h-12 lg:w-16 lg:h-16" />
                    RE-SCI de EPTAM
                     </div>
                    </a>
                </div>
                `,
                icon: UserGroupIcon,
                iconClass: 'text-blue-500',
                iconSize: 8
              }
            },
            {
              title: 'RIP de EPTAM',
              component: DynamicContent,
              componentProps: {
                title: 'RIP de EPTAM',
                content: `
                <div class="flex justify-center">
                  <a href="https://drive.google.com/file/d/1kwzINhuLzyVjL8g871t_V5QKch5jojLD/view?usp=sharing" target="_blank" rel="noopener noreferrer">
                    <div class="flex gap-3 items-center text-white">
                      <img src="https://img.icons8.com/?size=100&id=13417&format=png&color=000000 " alt="ícono" class="w-12 h-12 md:w-12 md:h-12 lg:w-16 lg:h-16" />
                      RIP de EPTAM
                     </div>
                    </a>
                </div>
                `,
                icon: UserGroupIcon,
                iconClass: 'text-blue-500',
                iconSize: 8
              }
            },
          ]
        },
        {
          title: "Informacion de GEstion",
          image: require('../assets/intitucional/gestion.webp'),
          options: [
          {
              title: 'INFORME DE GESTIÓN POA 2023',
              component: DynamicContent,
              componentProps: {
                title: 'INFORME DE GESTIÓN POA 2023',
                content: `
                <div class="flex justify-center">
                  <a href="https://drive.google.com/file/d/1ntxDmMPkgsGSTXkri4HK9BAV16gDf6lR/view?usp=sharing" target="_blank" rel="noopener noreferrer">
                    <div class="flex gap-3 items-center text-white">
                      <img src="https://img.icons8.com/?size=100&id=13417&format=png&color=000000 " alt="ícono" class="w-12 h-12 md:w-12 md:h-12 lg:w-16 lg:h-16" />
                      CROQUIS
                     </div>
                    </a>
                </div>
                `,
                icon: DocumentTextIcon,
                iconClass: 'text-blue-500',
                iconSize: 8
              }
            },
          ]
        },
        // Puedes agregar más elementos aquí siguiendo la misma estructura
      ],
      selectedOption: null,
    };
  },
  methods: {
    updateInfoSection(option) {
      this.selectedOption = option;
      this.$refs.infoSection.scrollIntoView({ behavior: 'smooth' });
    },
  },
};
</script>