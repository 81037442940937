<template>
    <div class="w-full px-6 pt-8 pb-0">
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            <div v-for="(card, index) in infoCards" :key="index" 
                :class="[
                    'bg-gradient-to-t from-blue-900 via-blue-500 to-blue-900 rounded-lg shadow-md overflow-hidden transition-transform duration-300 hover:transform hover:scale-105 mb-4',
                    { 'flex-col h-full': isViajeFamiliar },
                    { 'flex h-auto': !isViajeFamiliar && !isInstitucional },
                    { 'flex flex-col': isInstitucional }
                ]">
                
                <!-- Imagen -->
                <div v-if="!isViajeFamiliar && !isInstitucional" class="w-2/5 bg-blue-600">
                    <img :src="card.image" :alt="card.title" class="w-full h-full object-cover">
                </div>
                <div v-if="isViajeFamiliar || isInstitucional" class="w-full h-64">
                    <img :src="card.image" :alt="card.title" class="w-full h-full object-cover">
                </div>

                <!-- Contenido de la tarjeta -->
                <div v-if="!isInstitucional && !isViajeFamiliar" class="w-3/5 p-4">
                    <h2 class="text-xl font-bold text-white mb-2">{{ card.title }}</h2>
                    <p class="text-white text-justify" v-html="card.content"></p>
                </div>
                <div v-if="!isInstitucional && isViajeFamiliar" class="px-3 pt-2 overflow-hidden">
                    <h2 class="text-xl font-bold text-white mb-2 text-center">{{ card.title }}</h2>
                    <p class="text-white text-justify max-h-32 overflow-y-auto pr-2 mb-0" v-html="card.content"></p>
                </div>
                
                <!-- Opciones cuando es Institucional -->
                <div v-if="isInstitucional" class="w-full p-4">
                    <h2 class="text-xl font-bold text-white mb-2 text-center">{{ card.title }}</h2>
                    <div class="grid grid-cols-2 gap-2">
                        <button v-for="(option, idx) in card.options" :key="idx" @click="$emit('option-clicked', option)"
                            class="block bg-white text-blue-700 font-semibold px-2 py-1 rounded mt-2 hover:bg-yellow-400">
                            {{ option.title }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        infoCards: {
            type: Array,
            required: true,
        },
        isViajeFamiliar: {
            type: Boolean,
            default: false,
        },
        isInstitucional: {
            type: Boolean,
            default: false, // Por defecto, el componente muestra el contenido regular
        },
    },
};
</script>