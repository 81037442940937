<template>
    <div class="mt-3 flex flex-col items-center px-4">
        <h1 class="text-white text-2xl font-bold pb-3">
            Itinerario
        </h1>
        
        <!-- Contenedor principal en columna -->
        <div class="w-full lg:w-1/2 flex flex-col gap-4">
            <!-- Contenedor del carrusel -->
            <div class="relative h-96 rounded-2xl shadow-neon overflow-hidden w-full"
                 @mouseenter="pauseSlideshow"
                 @mouseleave="resumeSlideshow">
                <div class="h-full w-full flex transition-transform duration-500 ease-in-out"
                     :style="{ transform: `translateX(-${currentIndex * 100}%)` }">
                    <img 
                        v-for="(image, index) in images"
                        :key="index"
                        :src="image" 
                        :alt="'Slide ' + (index + 1)"
                        class="w-full h-full object-fill flex-shrink-0"
                    >
                </div>

                <!-- Indicadores de posición -->
                <div class="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
                    <button
                        v-for="(_, index) in images"
                        :key="index"
                        @click="manualSlide('goto', index)"
                        class="w-2 h-2 rounded-full transition-all"
                        :class="index === currentIndex ? 'bg-white' : 'bg-white bg-opacity-50'"
                    >
                    </button>
                </div>
            </div>

            <!-- Contenedor de botones centrados -->
            <div class="flex justify-center items-center gap-4">
                <button 
                    @click="manualSlide('prev')"
                    class="bg-black bg-opacity-50 text-white p-3 rounded-full hover:bg-opacity-75 transition-all"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
                    </svg>
                </button>
                
                <button 
                    @click="manualSlide('next')"
                    class="bg-black bg-opacity-50 text-white p-3 rounded-full hover:bg-opacity-75 transition-all"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
                    </svg>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TheItinerary',
    data() {
        return {
            currentIndex: 0,
            images: [
                require('@/assets/itinerario.webp'),
                require('@/assets/itinerario2.webp'),
            ],
            sliding: false,
            intervalId: null,
            autoplayEnabled: true
        }
    },
    mounted() {
        this.startSlideshow();
    },
    beforeUnmount() {
        this.stopSlideshow();
    },
    methods: {
        startSlideshow() {
            if (this.intervalId) return;
            this.intervalId = setInterval(() => {
                if (this.autoplayEnabled) {
                    this.nextSlide();
                }
            }, 12000);
        },
        stopSlideshow() {
            if (this.intervalId) {
                clearInterval(this.intervalId);
                this.intervalId = null;
            }
        },
        pauseSlideshow() {
            this.autoplayEnabled = false;
        },
        resumeSlideshow() {
            this.autoplayEnabled = true;
        },
        restartSlideshowTimer() {
            this.stopSlideshow();
            this.startSlideshow();
        },
        nextSlide() {
            if (this.sliding) return;
            this.sliding = true;
            this.currentIndex = (this.currentIndex + 1) % this.images.length;
            setTimeout(() => {
                this.sliding = false;
            }, 500);
        },
        previousSlide() {
            if (this.sliding) return;
            this.sliding = true;
            this.currentIndex = this.currentIndex === 0 
                ? this.images.length - 1 
                : this.currentIndex - 1;
            setTimeout(() => {
                this.sliding = false;
            }, 500);
        },
        manualSlide(action, index) {
            if (this.sliding) return;
            
            // Reiniciar el temporizador cuando hay interacción manual
            this.restartSlideshowTimer();
            
            switch(action) {
                case 'prev':
                    this.previousSlide();
                    break;
                case 'next':
                    this.nextSlide();
                    break;
                case 'goto':
                    if (index === this.currentIndex) return;
                    this.sliding = true;
                    this.currentIndex = index;
                    setTimeout(() => {
                        this.sliding = false;
                    }, 500);
                    break;
            }
        }
    }
}
</script>