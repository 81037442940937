<template>
    <div class="bg-gray-900" @click="handleGlobalClick">
        <Navbar ref="navbar" @dropdown-opened="dropdownOpened = true" @dropdown-closed="dropdownOpened = false"
            @menu-opened="menuOpened = true" @menu-closed="menuOpened = false" />
        <main class="min-h-screen mt-[75px]">
            <router-view />
        </main>
        <button 
            @click="scrollToTop"
            class="fixed bottom-28 right-6 bg-yellow-400 hover:bg-yellow-300 p-2 rounded-full shadow-lg transition-all duration-300"
        >
            <ChevronUpIcon class="h-5 w-5 text-blue-600 stroke-2 stroke-stone-950" />
        </button>
        <FloatingChatButton />
        <FootPage />
    </div>
</template>

<script>
import { ChevronUpIcon } from '@heroicons/vue/24/outline'
import Navbar from '@/components/NavBar.vue';
import FootPage from '@/components/FootPage.vue';
import FloatingChatButton from '@/components/FloatingChatButton.vue';

export default {
    components: {
        Navbar,
        FootPage,
        FloatingChatButton,
        ChevronUpIcon
    },
    data() {
        return {
            dropdownOpened: false,
            menuOpened: false,
        };
    },
    methods: {
        handleGlobalClick(event) {
            if ((this.dropdownOpened || this.menuOpened) && !this.$refs.navbar.$el.contains(event.target)) {
                this.$refs.navbar.closeDropdown();
                this.$refs.navbar.closeMenu();
            }
        },
        scrollToTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        },
    },
};
</script>
