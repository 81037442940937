<template>
  <div class="mt-6 mb-3 scroll-mt-24" @click="closeDropdown">
    <!-- Dropdown para seleccionar la ciudad -->
    <div class="flex justify-center">
      <div class="relative inline-block text-left" @click.stop>
        <!-- Texto y botón en la misma línea -->
        <div class="flex items-center gap-2">
          <div class="text-[22px] font-bold text-white">Ofertas desde</div>
          <button @click="toggleDropdown" class="inline-flex justify-between text-lg py-2 items-center ml-2 font-bold bg-blue-700 text-white px-3 rounded-xl hover:bg-blue-600">
            {{ selectedCity || 'La Paz' }}
            <svg class="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
            </svg>
          </button>
        </div>
        
        <!-- Menú de opciones para seleccionar una ciudad -->
        <div v-if="dropdownOpen" class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
          <div class="rounded-md" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            <a @click="selectCity('La Paz')" class="block px-4 py-2 text-sm font-semibold text-blue-800 hover:bg-blue-600 hover:text-white border-b border-blue-500 hover:rounded-t-md cursor-pointer" role="menuitem">La Paz</a>
            <a @click="selectCity('Santa Cruz')" class="block px-4 py-2 text-sm font-semibold text-blue-700 border-b border-blue-500 hover:bg-blue-600 hover:text-white cursor-pointer" role="menuitem">Santa Cruz</a>
            <a @click="selectCity('Cobija')" class="block px-4 py-2 text-sm font-semibold text-blue-700 border-b border-blue-500 hover:rounded-t-md hover:bg-blue-600 hover:text-white cursor-pointer " role="menuitem">Cobija</a>
            <a @click="selectCity('Cochabamba')" class="block px-4 py-2 text-sm font-semibold text-blue-700 hover:bg-blue-600 hover:text-white rounded-b-md cursor-pointer" role="menuitem">Cochabamba</a>
          </div>
        </div>
      </div>
    </div>

    <!-- Mostrar las imágenes de la ciudad seleccionada -->
    <div v-if="selectedCity" class="mt-6">
      <div class="grid lg:grid-cols-3 sm:gap-2 lg:gap-10 px-8 lg:px-12">
        <div v-for="(image, index) in cityImages[selectedCity]" :key="`${selectedCity}-${index}`" class="overflow-hidden rounded-2xl shadow-md mb-3">
          <img 
            :src="image" 
            :alt="`Imagen de ${selectedCity}`" 
            class="w-full h-80 sm:h-96 md:h-96 lg:h-80 object-fill transform transition-all duration-500"
            :class="{ 
              'opacity-0 scale-95': !imageLoaded[`${selectedCity}-${index}`] || isChangingCity, 
              'opacity-100 scale-100 hover:scale-110': imageLoaded[`${selectedCity}-${index}`] && !isChangingCity 
            }"
            @load="onImageLoad(selectedCity, index)"
            loading="lazy"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, onMounted, watch } from 'vue';

export default {
  setup() {
    const dropdownOpen = ref(false);
    const selectedCity = ref('La Paz');
    const imageLoaded = reactive({});
    const isChangingCity = ref(false);

    const cityImages = {
      'La Paz': [
        require('@/assets/lapaz/cobi.webp'),
        require('@/assets/lapaz/cocha.webp'),
        require('@/assets/lapaz/santa.webp'),
      ],
      'Santa Cruz': [
        require('@/assets/santa/lpz.webp'),
        require('@/assets/santa/cocha.webp'),
        require('@/assets/santa/cobi.webp'),
      ],
      'Cobija': [
        require('@/assets/cobija/santaa.webp'),
        require('@/assets/cobija/cocha.webp'),
        require('@/assets/cobija/lp.webp'),
      ],
      'Cochabamba': [
        require('@/assets/cocha/cobi.webp'),
        require('@/assets/cocha/lp.webp'),
        require('@/assets/cocha/santa.webp'),
      ],
    };

    const toggleDropdown = () => {
      dropdownOpen.value = !dropdownOpen.value;
    };

    const selectCity = (city) => {
      if (city !== selectedCity.value) {
        isChangingCity.value = true;
        setTimeout(() => {
          selectedCity.value = city;
          dropdownOpen.value = false;
          preloadImages(city);
        }, 300); // Pequeño retraso para la transición de salida
      } else {
        dropdownOpen.value = false;
      }
    };

    const closeDropdown = () => {
      if (dropdownOpen.value) {
        dropdownOpen.value = false;
      }
    };

    const onImageLoad = (city, index) => {
      imageLoaded[`${city}-${index}`] = true;
      if (index === cityImages[city].length - 1) {
        // Última imagen cargada
        setTimeout(() => {
          isChangingCity.value = false;
        }, 100); // Pequeño retraso para asegurar que todas las imágenes estén listas
      }
    };

    const preloadImages = (city) => {
      Object.keys(imageLoaded).forEach(key => {
        if (key.startsWith(city)) {
          imageLoaded[key] = false;
        }
      });
      cityImages[city].forEach((src, index) => {
        const img = new Image();
        img.src = src;
        img.onload = () => onImageLoad(city, index);
      });
    };

    onMounted(() => {
      preloadImages(selectedCity.value);
    });

    watch(selectedCity, (newCity) => {
      preloadImages(newCity);
    });

    return {
      dropdownOpen,
      selectedCity,
      imageLoaded,
      cityImages,
      isChangingCity,
      toggleDropdown,
      selectCity,
      closeDropdown,
      onImageLoad,
      metaInfo: {
        title: `Ofertas de viaje desde ${selectedCity.value}`,
        meta: [
          {
            name: 'description',
            content: `Descubre las mejores ofertas de vuelo desde ${selectedCity.value}. Compara precios y explora destinos.`,
          },
        ],
      },
    };
  },
};
</script>

<style scoped>
/* Estilos adicionales si son necesarios */
</style>