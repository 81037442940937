<template>
    <div class="floating-chat-button fixed bg-white/30 backdrop-blur-lg bottom-6 right-6 flex items-center shadow-neon p-[12px] rounded-full cursor-pointer">
      <a href="https://wa.me/message/Q4ZX3LQ33G5WN1" target="_blank" class="flex items-center no-underline text-current mb-0">
        <img src="../assets/imagenes/whatsapp.png" alt="WhatsApp" class="w-10 h-10 mr-2">
        <div class="hidden md:block">
          <p class="font-semibold text-black text-[16px] mb-0">Call Center</p>
          <!-- <p class="text-sm font-medium">Chatea con nosotros</p> -->
        </div>
      </a>
    </div>
  </template>
  
  <script>
  export default {
    name: 'FloatingChatButton',
  };
  </script>