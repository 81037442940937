<template>
    <div>
        <div class="flex flex-col py-7 items-center">
            <div class="flex w-full sm:w-[600px] px-3 lg:px-0 lg:w-[800px] overflow-hidden justify-center rounded-2xl">
                <div class="w-[242px] sm:w-[400px] lg:w-[600px]">
                    <img src="../assets/requi-viajar/imagen-familiaa.webp" alt="silla"
                        class="h-64 w-full object-cover rounded-l-2xl">
                </div>
                <div
                    class="flex w-[130px] sm:w-[300px] lg:w-[400px] h-64 bg-gradient-to-t from-cyan-800 via-cyan-500 to-cyan-800  items-center justify-center rounded-r-2xl">
                    <p class="text-[16px] sm:text-[18px] lg:text-[22px] font-bold text-white text-wrap px-3 sm:px-0 text-center">
                        Requisitos para viajar
                    </p>
                </div>
            </div>
        </div>
        <div class="w-full px-4 py-2">
            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                <div v-for="(card, index) in infoCards" :key="index"
                    class="bg-gradient-to-t from-blue-900 via-blue-500 to-blue-900 rounded-lg shadow-md overflow-hidden transition-transform duration-300 hover:transform hover:scale-105 flex mb-4">
                    <div class="w-2/5 bg-blue-600">
                        <img :src="card.image" :alt="card.title" class="w-full h-full object-cover">
                    </div>
                    <div class="w-3/4 p-4">
                        <h2 class="text-xl font-bold text-white mb-2">{{ card.title }}</h2>
                        <!-- <p class="text-white text-justify">{{ card.content }}</p> -->
                        <p class="text-white text-justify" v-html="card.content"></p>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'RequisitosViaje',
    data() {
        return {
            infoCards: [
                {
                    title: "Documentación General:",
                    content: `
        <ul class="list-disc pl-4">
            <li>Cédula de identidad o pasaporte del menor: Obligatorio para vuelos nacionales</li>
            <li>Cédula de identidad del acompañante (padres o tutores): Los adultos que acompañen al menor deben presentar su identificación.</li>
        </ul>`,
                    image: require('../assets/requi-viajar/Documentacion.webp') // Usando require para la imagen
                },
                {
                    title: "Viaje con Ambos Padres:",
                    content: `
        <ul class="list-disc pl-4">
            <li>Si ambos padres están presentes, no se requiere una autorización adicional. Solo deben presentar su cédula de identidad o pasaporte, junto con el del menor</li>
        </ul>`,
                    image: require('../assets/requi-viajar/ViajePadres.webp') // Usando require
                },
                {
                    title: "Viaje con Uno de los Padres:",
                    content: `
        <ul class="list-disc pl-4">
            <li>Si el menor viaja solo con uno de los padres, se necesita una autorización del padre o madre ausente.</li>
            <li>La autorización debe incluir detalles del destino y duración del viaje, acompañada de una copia de la identificación del padre que da el permiso.</li>
        </ul>`,
                    image: require('../assets/requi-viajar/ViajemUnoPadres.webp') // Usando require
                },
                {
                    title: "Viaje con Otros Acompañantes o Solo:",
                    content: `
        <ul class="list-disc pl-4">
            <li>Si el menor viaja con un familiar u otra persona que no es su padre o madre, se debe presentar una autorización de ambos padres.</li>
            <li>Si el menor viaja solo, además de la autorización, TAMEP requiere que se complete un formulario de menor no acompañado, que detalla quién entregará y recibirá al menor en aeropuerto.</li>
        </ul>`,
                    image: require('../assets/requi-viajar/acompanantes.webp') // Usando require
                },
                {
                    title: "Consideraciones Médicas:",
                    content: `
        <ul class="list-disc pl-4">
            <li>Si el menor tiene alguna condición médica, podrías necesitar un certificado médico o declaración de aptitud para volar según las políticas de TAMEP.</li>
        </ul>`,
                    image: require('../assets/requi-viajar/consideraciones-medicas.webp') // Usando require
                }
            ]
        }
    },
    methods: {
        getImageUrl(image) {
            return require.ensure([], () => require(`../assets/requi-viajar/${image}`));
        }
    }
}
</script>