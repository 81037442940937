<template>
    <div class="flex flex-col py-7 items-center">
        <div class="flex w-full sm:w-[600px] px-3 lg:px-0 lg:w-[800px] overflow-hidden justify-center rounded-2xl">
            <div class="w-[242px] sm:w-[400px] lg:w-[600px]">
                <img src="../assets/mascotas/mascota.webp" alt="silla"
                    class="h-64 w-full object-cover rounded-l-2xl">
            </div>
            <div class="flex w-[130px] sm:w-[300px] lg:w-[400px] h-64 bg-gradient-to-t from-cyan-800 via-cyan-500 to-cyan-800 justify-center items-center rounded-r-2xl">
                <p class="text-[16px] sm:text-[18px] lg:text-[22px] font-bold text-white text-wrap px-3 sm:px-0 text-center">
                    Mascotas a bordo
                </p>
            </div>
        </div>
        <div>
            <CardsNecesidades :infoCards="cardsData" />
        </div>
    </div>
</template>

<script>
import CardsNecesidades from './CardsNecesidades.vue';

export default {
    name: 'PoliticasMascotas',
    components: {
        CardsNecesidades,
    },
    data() {
        return {
            cardsData: [
                {
                    title: "CABINA",
                    content: "Su mascota (Perro, Gato), debe pesar entre los 5 kg (Mascota + Canil).",
                    image: require('../assets/mascotas/CABINA.webp') // Usando require
                },
                {
                    title: "CABINA (ASIENTO ESPECIAL)",
                    content: "Si el pasajero requiere un pasaje especial para su mascota, este tendrá la tarifa que este disponible en sistema.",
                    image: require('../assets/mascotas/NOTA.webp') // Usando require
                },
                {
                    title: "BUZON",
                    content: "Las mascotas que tengan un peso mayor a los 6 kg deberán ir en buzón (Mascota + Canil).",
                    image: require('../assets/mascotas/3.webp') // Usando require
                },
                {
                    title: "CANIL",
                    content: "Las dimensiones del canil deben tener un tamaño máximo de 1 m por 1.20 m. para buzón.",
                    image: require('../assets/mascotas/CANIL.webp') // Usando require
                },
                {
                    title: "APOYO EMOCIONAL O SERVICIO",
                    content: "Las mascotas de apoyo emocional o servicio deben contar con el certificado del mismo para ser demostrado y contar con los requisitos mencionados.",
                    image: require('../assets/mascotas/carnet.webp') // Usando require
                },
            ]
        }
    }
}
</script>