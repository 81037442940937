<template>
    <div>
        <div :class="[
            // Transiciones y clase predeterminada para pantallas grandes
            'transition-all duration-300',
            'h-[412px] sm:h-[358px] mb-2 sm:mb-0 mt-[-35px] md:h-[215px] lg:h-[152px] sm:my-[-35px] overflow-hidden',

            'lg-mid:h-[152px]',
            
            'w-auto',
            
            // Cuando el widget está fijo, siempre utiliza w-full
            {
                'md:fixed md:top-[12px] md:left-0 md:right-0 md:z-40  md:w-full lg:w-full':
                isWidgetFixed
            }
        ]" id="home">
            <div :class="[
                'mx-auto widget-container',
                isWidgetFixed ? 'sm:h-48' : ''
            ]">
                <WidgetTamepSearch />
            </div>
        </div>
        <div v-if="isWidgetFixed" :class="['w-full', 'h-0 sm:h-[10px]']"></div>
        <VideoTam />
        <TheItinerary id="itinerario" />
        <CitySelector id="ofertas" />
        <NewDestinations id="proximos" />
        <OurDirections id="oficinas" />
    </div>
</template>

<script>
import VideoTam from './VideoTam.vue';
import CitySelector from './CitySelector.vue';
import NewDestinations from './NewDestinations.vue';
import OurDirections from './OurDirections.vue';
import TheItinerary from './TheItinerary.vue';
import WidgetTamepSearch from './WidgetTamepSearch.vue';

export default {
    name: 'HomePrincipal',
    components: {
        VideoTam,
        CitySelector,
        NewDestinations,
        OurDirections,
        TheItinerary,
        WidgetTamepSearch
    },
    data() {
        return {
            isWidgetFixed: false,
            scrollThreshold: 100,
        };
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    unmounted() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        handleScroll() {
            this.isWidgetFixed = window.scrollY > this.scrollThreshold;
        }
    }
};
</script>