<template>
    <div class="min-h-screen bg-slate-900 py-12 px-4 sm:px-6 lg:px-8">
        <div class="max-w-7xl mx-auto">
            <h1 class="text-4xl font-bold text-center text-white mb-16">Carga y Envíos</h1>
            <div class="space-y-4">
                <div v-for="(service, index) in services" :key="index"
                    @mouseover="activeSection = index"
                    @mouseleave="activeSection = null"
                    @click="toggleExpansion(index)" 
                    class="relative overflow-hidden transition-all duration-300 ease-in-out cursor-pointer"
                    :class="[
                        activeSection === index ? 'bg-slate-800' : 'bg-slate-800/50', 
                        'hover:bg-slate-800 rounded-lg'
                    ]">
                    <div class="p-6 md:p-8">
                        <div class="flex flex-col md:flex-row md:items-center gap-6">
                            <div
                                :class="[
                                    'flex items-center justify-center w-12 h-12 rounded-full',
                                    activeSection === index ? 'bg-blue-500' : 'bg-blue-400/20', 
                                    'transition-colors duration-300'
                                ]">
                                <!-- Usar SVG de Feather -->
                                <div v-html="service.iconSvg" :class="[
                                    'w-6 h-6', 
                                    activeSection === index ? 'text-white' : 'text-blue-400'
                                ]"></div>
                            </div>
                            <div class="flex-1">
                                <h2 class="text-xl font-semibold text-white mb-2">{{ service.title }}</h2>
                                <!-- Aquí usamos max-height y overflow para animar la expansión -->
                                <p :class="[
                                    'text-slate-300 transition-all duration-300', 
                                    activeSection === index ? 'opacity-100' : 'opacity-70', 
                                    expandedIndex === index ? 'max-h-96' : 'max-h-8 overflow-hidden',
                                    'transition-max-height ease-in-out'
                                ]">
                                    {{ service.description }}
                                </p>
                                <div v-if="expandedIndex === index && service.extraDescription" class="text-slate-300 mt-4">
                                    <ul class="list-disc pl-6">
                                        <li v-for="(item, idx) in service.extraDescription" :key="idx">{{ item }}</li>
                                    </ul>
                                </div>
                                <div v-if="service.contactInfo && activeSection === index"
                                    class="mt-4 pt-4 border-t border-slate-700">
                                    <div class="flex items-center space-x-2 text-blue-300">
                                        <div v-html="phoneSvg" class="w-4 h-4"></div>
                                        <span>{{ service.contactInfo.phone }}</span>
                                    </div>
                                    <div class="flex items-center space-x-2 text-blue-300 mt-2">
                                        <div v-html="envelopeSvg" class="w-4 h-4"></div>
                                        <span>{{ service.contactInfo.email }}</span>
                                    </div>
                                </div>
                            </div>
                            <!-- Este es el botón de la flecha, que ahora se oculta cuando el servicio está expandido -->
                            <div v-if="expandedIndex !== index" :class="[
                                'hidden md:flex items-center justify-center transition-transform duration-300',
                                activeSection === index ? 'translate-x-0 opacity-100' : 'translate-x-4 opacity-0'
                            ]">
                                <div class="w-12 h-12 rounded-full bg-blue-500/10 flex items-center justify-center">
                                    <svg class="w-6 h-6 text-blue-400" fill="none" viewBox="0 0 24 24"
                                        stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                            d="M9 5l7 7-7 7" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div :class="[
                        'absolute bottom-0 left-0 w-full h-1 bg-gradient-to-r from-blue-600 to-blue-400 transform origin-left transition-transform duration-300',
                        activeSection === index ? 'scale-x-100' : 'scale-x-0'
                    ]" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
import feather from 'feather-icons';

export default {
    name: 'CargaEnvio',
    setup() {
        const activeSection = ref(null);
        const expandedIndex = ref(null); // Estado para manejar la expansión

        const toggleExpansion = (index) => {
            // Cambiar el índice expandido
            expandedIndex.value = expandedIndex.value === index ? null : index;
        };

        // Preparamos los SVGs de feather icons como strings
        const phoneSvg = feather.icons.phone.toSvg();
        const envelopeSvg = feather.icons.mail.toSvg();
        const truckSvg = feather.icons.truck.toSvg();
        const clipboardSvg = feather.icons['clipboard'].toSvg();
        const buildingSvg = feather.icons['home'].toSvg(); // Usando 'home' como referencia para Building
        const shoppingCartSvg = feather.icons['shopping-cart'].toSvg();
        const alertTriangleSvg = feather.icons['alert-triangle'].toSvg();
        const featherSvg = feather.icons.feather.toSvg();
        const headphonesSvg = feather.icons.headphones.toSvg();


        const services = [
            {
                title: "Información sobre tus Encomiendas",
                description: "Revisa el estado de tus encomiendas ingresando el número de seguimiento en nuestro portal.",
                iconSvg: clipboardSvg,
                extraDescription: [
                    "La recepción de Carga se deberá hacer únicamente en oficinas de EPTAM Carga o en oficinas de Agente Acreditado correctamente.",
                    "El envío de paquetes, encomiendas y/o carga debe tener rotulado el nombre completo teléfono del consignatario, siendo el tamaño del paquete similar o mayor a las dimensiones de un sobre manila tamaño carta (32 cm X 25 cm).",
                    "Toda Carga debe ser entregada, abierta para su respectiva inspección.",
                    "Es responsabilidad del cliente contar con el material necesario para terminar de embalar sus encomiendas.",
                    "TAMEP, tiene la potestad de rechazar un embarque en caso de no poder efectuar la inspección según procedimientos o que el embalaje sea defectuoso.",
                    "El remitente debe identificarse con un documento legal y vigente al momento de la recepción, ejm: carnet de identidad, licencia de conducir, pasaporte o libreta militar.",
                    "Todo despacho voluminoso o de mayor peso, así como cargas especiales (AVI, HUM, PER, otros), deberán ser coordinados entre el cliente y TAMEP, por lo menos con un periodo de 48 horas antes del vuelo programado. Caso contrario estará sujeta a espacio."
                ]
            },
            {
                title: "Tamep Cargo Corporativo",
                description: "Ofrecemos soluciones personalizadas para empresas. Contáctanos para más información.",
                iconSvg: buildingSvg,
                extraDescription: [
                    "TAMEP Carga, busca generar alianzas comerciales con clientes corporativos brindándoles tarifas especiales y despachos preferenciales.",
                    "TAMEP Carga, comprometida con el desarrollo nacional ofreciendo despachos económicos y atendiendo las solicitudes de ayuda (apoyo) de la sociedad en general."
                ]
            },
            {
                title: "Atención al Cliente",
                description: "Servicio de atención preferencial para grupos vulnerables.",
                iconSvg: headphonesSvg,
                extraDescription: [
                    "Atención preferencial, a: personas con discapacidad, personas de la tercera edad y mujeres gestantes.",
                    "Información oportuna y resolución de conflictos de manera eficiente."
                ]
            },
            {
                title: "Mascotas",
                description: "Transportamos tus mascotas con el mayor cuidado y atención. Consulta nuestras políticas de transporte de mascotas.",
                iconSvg: featherSvg,
                extraDescription: [
                    "Animales vivos, previa reserva, deberán ser traídos a la oficina de carga con dos horas de anticipación al vuelo y en una jaula adecuada.",
                    "Deberán traer certificado veterinario que indique que está apto para viajar, cartilla de vacunas.",
                    "Restos Humanos, previa reserva, deberán ser traídos a Oficina de carga con dos horas de anticipación al vuelo con toda la documentación requerida."
                ]
            },
            {
                title: "Alimentos",
                description: "Garantizamos el transporte seguro y rápido de alimentos perecederos y no perecederos.",
                iconSvg: shoppingCartSvg,
                extraDescription: [
                    "Carga perecedera (alimentos, flores, carnes, frutas, etc.), deberá estar embalada de manera que proteja el producto y/o artículos a ser transportados."
                ]
            },
            {
                title: "Mercancía Peligrosa",
                description: "Contamos con las certificaciones necesarias para el transporte de mercancías peligrosas. Consulta nuestras políticas.",
                iconSvg: alertTriangleSvg,
                extraDescription: [
                    "El transporte de Mercancías Peligrosas debe ser declarado estrictamente de acuerdo a las normas y reglamentación de Mercancías Peligrosas IATA.",
                    "No se permite el envío de valorados como ser dinero o joyas."
                ]
            },
            {
                title: "Entrega de Carga",
                description: "Ofrecemos servicios de entrega de carga a nivel nacional e internacional. Consulta nuestras tarifas y tiempos de entrega.",
                iconSvg: truckSvg,
                extraDescription: [
                    "La carga será entregada con Carnet de identidad vigente del consignatario, una hora después del arribo del vuelo.",
                    "Los reclamos de pérdidas o faltantes en sus envíos no se aceptarán una vez retirada la encomienda de la bodega de carga.",
                    "Se recomienda escanear el contrato de transporte de carga que se encuentra en las oficinas."
                ]
            }
        ];

        return {
            activeSection,
            expandedIndex,
            toggleExpansion, // Función para manejar el clic
            services,
            phoneSvg,
            envelopeSvg,
        };
    }
}
</script>