<template>
  <nav class="bg-gradient-to-t from-blue-950 via-blue-800 to-blue-500 shadow-md fixed top-0 left-0 right-0 z-50 h-[75px]">
    <div class="w-full px-[51px] mx-0 flex justify-between items-center h-full">
      <div class="flex items-center w-full">
        <div class="block lg:hidden">
          <!-- Botón de menú hamburguesa -->
          <button @click="toggleMenu" class="text-white focus:outline-none">
            <div class="space-y-2 pt-1">
              <!-- Líneas del menú hamburguesa -->
              <span
                :class="[
                  'block w-8 h-0.5 bg-white transition-transform duration-300',
                  isMenuOpen ? 'rotate-45 translate-y-2.5' : ''
                ]"
              ></span>
              <span
                :class="[
                  'block w-8 h-0.5 bg-white transition-all duration-300',
                  isMenuOpen ? 'opacity-0' : 'opacity-100'
                ]"
              ></span>
              <span
                :class="[
                  'block w-8 h-0.5 bg-white transition-transform duration-300',
                  isMenuOpen ? '-rotate-45 -translate-y-2.5' : ''
                ]"
              ></span>
            </div>
          </button>
        </div>

        <!-- Logo -->
        <div class="flex justify-center w-full md:justify-center lg:w-auto">
          <router-link to="/" @click="handleClick" class="relative group overflow-visible">
            <img 
              src="../assets/imagenes/logotam.png" 
              alt="Tam Logo" 
              class="h-14 sm:mr-10"
            />
          </router-link>
        </div>

        <!-- Menú en pantallas grandes -->
        <ul class="hidden lg:flex space-x-6 pl-0 mb-0">
          <!-- <li class="relative group">
            <router-link to="/" @click="closeDropdown" class="text-white hover:text-blue-500 font-bold text-lg pb-2">
              <HomeIcon class="h-6 w-6 text-white"/>
            </router-link>
            <span class="absolute left-0 bottom-[-6px] w-0 h-1 bg-gradient-to-r from-blue-400 to-blue-800 transition-all duration-500 group-hover:w-full"></span>
          </li> -->
          <li class="relative group">
            <a href="#" @click.prevent="openDropdown('Ofertas y destinos')" class="text-white hover:text-blue-500 font-bold text-lg pb-2">Ofertas y destinos</a>
            <span class="absolute left-0 bottom-[-6px] w-0 h-1 bg-gradient-to-r from-blue-400 to-blue-800 transition-all duration-500 group-hover:w-full"></span>
          </li>
          <li class="relative group">
            <router-link to="/webcheking" @click="closeDropdown"  class="text-white hover:text-blue-500 font-bold text-lg pb-2">Web Check-in</router-link>
            <span class="absolute left-0 bottom-[-6px] w-0 h-1 bg-gradient-to-r from-blue-400 to-blue-800 transition-all duration-500 group-hover:w-full"></span>
          </li>
          <li class="relative group">
            <router-link to="/carga-envio" @click="closeDropdown"  class="text-white hover:text-blue-500 font-bold text-lg pb-2">Carga y envío</router-link>
            <span class="absolute left-0 bottom-[-6px] w-0 h-1 bg-gradient-to-r from-blue-400 to-blue-800 transition-all duration-500 group-hover:w-full"></span>
          </li>
          <li class="relative group">
            <a href="#" @click.prevent="openDropdown('Información y ayuda')" class="text-white hover:text-blue-500 font-bold text-lg pb-2">Información y ayuda</a>
            <span class="absolute left-0 bottom-[-6px] w-0 h-1 bg-gradient-to-r from-blue-400 to-blue-800 transition-all duration-500 group-hover:w-full"></span>
          </li>
        </ul>
      </div>

      <div class="relative group overflow-visible hidden sm:block">
        <img 
          src="../assets/escudo.gif" 
          alt="escudo" 
          class="h-16 ml-6"
        />
      </div>
    </div>

    <!-- Menú desplegable para dispositivos móviles -->
    <div v-if="isMenuOpen" class="lg:hidden">
      <ul class="flex flex-col bg-blue-950 p-4 mb-0">
        <!-- <li class="relative group mb-2">
          <router-link to="/" @click="closeMenu"  class="text-white hover:text-blue-500 font-bold">
            <HomeIcon class="h-6 w-6 text-white"/>
          </router-link>
        </li> -->
        <li class="relative group mb-2">
          <a href="#" @click.prevent="openDropdown('Ofertas y destinos')" class="text-white hover:text-blue-500 font-bold">Ofertas y destinos</a>
        </li>
        <li class="relative group mb-2">
          <router-link to="/webcheking" @click="closeMenu"  class="text-white hover:text-blue-500 font-bold">Web Check-in</router-link>
        </li>
        <li class="relative group mb-2">
          <router-link to="/carga-envio" @click="closeMenu"  class="text-white hover:text-blue-500 font-bold">Carga </router-link>
        </li>
        <li class="relative group mb-2">
          <a href="#" @click.prevent="openDropdown('Información y ayuda')" class="text-white hover:text-blue-500 font-bold">Información y ayuda</a>
        </li>
      </ul>
    </div>

    <!-- DropdownMenu dinámico -->
    <DropdownMenu 
      v-if="isDropdownOpen" 
      :menuType="selectedMenu" 
      @close-dropdown="closeDropdown"
      @option-selected="handleOptionSelected"
    />
  </nav>
</template>

<script>
import DropdownMenu from './DropdownMenu.vue';
// import { HomeIcon } from '@heroicons/vue/24/solid';

export default {
  components: {
    DropdownMenu,
    // HomeIcon
  },
  data() {
    return {
      isDropdownOpen: false,
      isMenuOpen: false,
      selectedMenu: null,
    };
  },
  methods: {
    openDropdown(menu) {
      if (this.isDropdownOpen && this.selectedMenu === menu) {
        this.closeDropdown();
      } else {
        this.selectedMenu = menu;
        this.isDropdownOpen = true;
        this.$emit('dropdown-opened');
      }
    },
    closeDropdown() {
      this.isDropdownOpen = false;
      this.selectedMenu = null;
      this.$emit('dropdown-closed');
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
      
      if (this.isMenuOpen) {
        this.$emit('menu-opened');
      } else {
        this.$emit('menu-closed');
      }
      
      if (!this.isMenuOpen) {
        this.closeDropdown();
      }
    },
    handleOptionSelected(option) {
      this.closeDropdown();
      this.closeMenu();
      this.$emit('option-selected', option);
    },
    closeMenu() {
      if (this.isMenuOpen) {
        this.isMenuOpen = false;
        this.$emit('menu-closed');
      }
    },
    handleClick() {
      if (window.innerWidth >=1024) {
        this.closeDropdown();
      } else {
        this.closeMenu();
        this.closeDropdown();
      }
    }
  },
};
</script>

<style scoped>
/* ...existing code... */
a:hover {
  color: #86adf5 !important; /* Cambiar el color al azul con !important */
}
/* ...existing code... */
</style>