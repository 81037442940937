<template>
    <div class="w-full">
        <widget-search-flight class="p-0"></widget-search-flight>
    </div>
</template>

<script>
export default {
    name: 'WidgetTamepSearch',
    mounted() {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = 'https://img.transclicksolutions.com/assets/lib/widget/prod/styles.css';
        link.onload = () => {
            const script = document.createElement('script');
            script.src = 'https://img.transclicksolutions.com/assets/lib/widget/prod/main.js';
            document.body.appendChild(script);
        };
        document.head.appendChild(link);
    }
}
</script>

<style scoped>
:deep(.col-12.my-4) {
    padding: 0 8px !important;
}

@media (min-width: 645px) {
    :deep(.col-12.my-4) {
        padding: 0 !important;
    }
}

/* Sobrescribimos el estilo para que no tenga esquinas redondeadas */
:deep(.card-body-search.card-body) {
    border-radius: 0 !important;
}
</style>