<template>
  <section class="py-0 bg-gray-900  ">
    <div class="flex flex-col py-7 items-center">
      <div class="flex w-full sm:w-[600px] px-3 lg:px-0 lg:w-[800px] overflow-hidden justify-center rounded-2xl">
          <div class="w-[242px] sm:w-[400px] lg:w-[600px]">
              <img src="../assets/equipaje/2.webp" alt="silla"
                  class="h-64 w-full object-cover rounded-l-2xl">
          </div>
          <div class="flex w-[130px] sm:w-[300px] lg:w-[400px] h-64 bg-gradient-to-t from-cyan-800 via-cyan-500 to-cyan-800  items-center justify-center rounded-r-2xl">
              <p class="text-[16px] sm:text-[18px] lg:text-[22px] font-bold text-white text-wrap px-3 sm:px-0 text-center">
                Prepara tu equipaje
              </p>
          </div>
      </div>
  </div>
    <p class="text-center text-xs sm:text-[16px] lg:text-xl font-bold mb-8 text-white ">Viaja a tu medida con el equipaje que necesitas</p>
    
    <div class="grid grid-cols-1 lg:grid-cols-3 gap-8 mx-3">
      <!-- Artículo Personal -->
      <div class="bg-white p-6 rounded-lg shadow-lg">
        <div class="flex justify-center mb-4">
          <img src="../assets/equipaje/articulo-personal.webp" alt="Artículo personal" class="w-52 h-52" />
        </div>
        <h2 class="text-xl font-semibold mb-2 text-blue-800">Artículo personal</h2>
        <ul class="list-disc ml-5 space-y-2">
          <li>Medidas máximas: 40 x 35 x 20 cm (alto, largo y ancho).</li>
          <li>Asegúrate que puedas ubicarlo debajo del asiento delantero.</li>
          <li>
            En todas nuestras <a href="#" class="text-red-500">tarifas</a> podrás llevar un artículo personal gratis, conoce las
            <a href="#" class="text-red-500">condiciones</a>.
          </li>
        </ul>
      </div>
      <!-- Equipaje de Mano -->
      <div class="bg-white p-6 rounded-lg shadow-lg">
        <div class="flex justify-center mb-4">
          <img src="../assets/equipaje/equipaje-de-mano.webp" alt="Equipaje de mano" class="w-60 h-52" />
        </div>
        <h2 class="text-xl font-semibold mb-2 text-blue-800">Equipaje de mano</h2>
        <ul class="list-disc ml-5 space-y-2">
          <li>Medidas máximas: 55 x 35 x 20 cm (alto, largo y ancho), incluyendo ruedas y manijas.</li>
          <li>Peso máximo: 5 kg.</li>
          <li>
            Incluido en todas las <a href="#" class="text-red-500">tarifas</a> a excepción de las tarifas basic y XS.
          </li>
          <li>
            Conoce más sobre el <a href="#" class="text-red-500">equipaje de mano</a>.
          </li>
        </ul>
      </div>
      <!-- Equipaje de Bodega -->
      <div class="bg-white p-6 rounded-lg shadow-lg">
        <div class="flex justify-center mb-4">
          <img src="../assets/equipaje/equipaje-de-bodega.webp" alt="Equipaje de bodega" class="w-52 h-52" />
        </div>
        <h2 class="text-xl font-semibold mb-2 text-blue-800">Equipaje de bodega</h2>
        <ul class="list-disc ml-5 space-y-2">
          <li>Medidas máximas: no debe exceder los 158 cm lineales (largo + ancho + alto).</li>
          <li>Peso máximo: 23 kg en Clase económica y 32 kg en business class.</li>
          <li>
            Las <a href="#" class="text-red-500">tarifas</a> classic, flex, business, L, M y XXL incluyen este equipaje.
          </li>
          <li>
            Conoce más sobre el <a href="#" class="text-red-500">equipaje de bodega</a>.
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: 'GestionEquipaje',
  metaInfo: {
    title: 'Gestión de Equipaje | Tu Sitio',
    meta: [
      { name: 'description', content: 'Encuentra toda la información que necesitas sobre cómo gestionar tu equipaje, desde artículos personales hasta equipaje de bodega.' },
      { name: 'keywords', content: 'equipaje, artículo personal, equipaje de mano, equipaje de bodega, tarifas equipaje' },
      { name: 'robots', content: 'index, follow' },
      { property: 'og:title', content: 'Gestión de Equipaje | Tu Sitio' },
      { property: 'og:description', content: 'Aprende a gestionar tu equipaje de forma sencilla y descubre las mejores tarifas para tus necesidades de viaje.' },
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:title', content: 'Gestión de Equipaje | Tu Sitio' },
    ]
  }
}
</script>