<template>
  <div class="flex-col bg-white p-2 md:px-4 md:py-2 lg:pb-8 lg:px-8 flex items-center gap-2 scroll-mt-24">
    <div class="flex w-full justify-center">
      <h1 class="font-bold text-blue-900 text-[18px] md:text-2xl lg:text-3xl">NUESTRAS OFICINAS</h1>
    </div>
    <!-- Información de contacto -->
    <div class="grid grid-cols-1 lg:grid-cols-2 gap-4">
      <div class="flex w-full lg:w-full border-b-4 pb-5 border-b-blue-800">
        <div class="w-[190px] sm:w-[350px] lg:w-[450px] overflow-hidden rounded-lg">
          <img src="../assets/aeropuertos/direccion-lp.png" alt="Edificio Cochabamba" class="w-full h-full object-cover">
        </div>
        <div class="flex flex-col w-1/2 ml-3 justify-center">
          <h2 class="text-[12px] md:text-[18px] lg:text-3xl font-bold text-gray-900 mb-6">LA PAZ</h2>
          <div class="space-y-6">
            <div class=" pb-4">
              <h3 class="text-[11px] md:text-[16px] lg:text-[20px] text-gray-600">
                Direccion:
              </h3>
              <p class="text-[11px] md:text-[16px] text-gray-600 mb-1">Av. Montes esq. José María Serrano N° 734</p>
              <p class="text-[11px] md:text-[16px] text-yellow-500 font-semibold mb-2">Teléfono: 22681111</p>
              <a href="https://maps.app.goo.gl/1FbHxN4ds8sxgS4b9" class="text-[10px] md:text-[15px] flex items-center text-blue-700 font-bold"
                target="_blank" rel="noopener noreferrer">
                <span class="material-icons mr-1">
                  <MapPinIcon class="md:h-5 md:w-5 h-4 w-4 lg:h-6 lg:w-6 text-green-600" />
                </span> CÓMO LLEGAR
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="flex w-full lg:w-full border-b-4 pb-5 border-b-blue-800">
        <div class="w-[190px] sm:w-[350px] lg:w-[450px] overflow-hidden rounded-lg">
          <img src="../assets/aeropuertos/aero-cocha.jpeg" alt="Edificio Cochabamba" class="w-full h-full object-cover">
        </div>
        <div class="flex flex-col w-1/2 ml-3 justify-center">
          <h2 class="text-[12px] md:text-[18px] lg:text-3xl font-bold text-gray-900 mb-6">COCHABAMBA</h2>
          <div class="space-y-6">
            <div class=" pb-4">
              <h3 class="text-[11px] md:text-[16px] lg:text-[20px] text-gray-600">
                Direccion:
              </h3>
              <p class="text-[11px] md:text-[16px] text-gray-600 mb-1">HRPC+7FV, Cochabamba</p>
              <p class="text-[11px] md:text-[16px] text-yellow-500 font-semibold mb-2">Teléfono: 22681111</p>
              <a href="https://maps.app.goo.gl/H23VGbmN3iUs3Ydn9" class="text-[10px] md:text-[15px] flex items-center text-blue-700 font-bold"
                target="_blank" rel="noopener noreferrer">
                <span class="material-icons mr-1">
                  <MapPinIcon class="md:h-5 md:w-5 h-4 w-4 lg:h-6 lg:w-6 text-green-600" />
                </span> CÓMO LLEGAR
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="flex w-full lg:w-full border-b-4 pb-5 border-b-blue-800">
        <div class="w-[190px] sm:w-[350px] lg:w-[450px] overflow-hidden rounded-lg">
          <img src="../assets/aeropuertos/aero-santa.jpeg" alt="Edificio Cochabamba" class="w-full h-full object-cover">
        </div>
        <div class="flex flex-col w-1/2 ml-3 justify-center">
          <h2 class="text-[12px] md:text-[18px] lg:text-3xl font-bold text-gray-900 mb-6">SANTA CRUZ</h2>
          <div class="space-y-6">
            <div class=" pb-4">
              <h3 class="text-[11px] md:text-[16px] lg:text-[20px] text-gray-600">
                Direccion:
              </h3>
              <p class="text-[11px] md:text-[16px] text-gray-600 mb-1">Av. G77, Santa Cruz de la Sierra</p>
              <p class="text-[11px] md:text-[16px] text-yellow-500 font-semibold mb-2">Teléfono: 22681111</p>
              <a href="https://maps.app.goo.gl/ombWPqgdmFXZiJW96" class="text-[10px] md:text-[15px] flex items-center text-blue-700 font-bold"
                target="_blank" rel="noopener noreferrer">
                <span class="material-icons mr-1">
                  <MapPinIcon class="md:h-5 md:w-5 h-4 w-4 lg:h-6 lg:w-6 text-green-600" />
                </span> CÓMO LLEGAR
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="flex w-full lg:w-full border-b-4 pb-5 border-b-blue-800">
        <div class="w-[190px] sm:w-[350px] lg:w-[450px] overflow-hidden rounded-lg">
          <img src="../assets/aeropuertos/aero-cobija.jpeg" alt="Edificio Cochabamba" class="w-full h-full object-cover">
        </div>
        <div class="flex flex-col w-1/2 ml-3 justify-center">
          <h2 class="text-[12px] md:text-[18px] lg:text-3xl font-bold text-gray-900 mb-6">COBIJA</h2>
          <div class="space-y-6">
            <div class=" pb-4">
              <h3 class="text-[11px] md:text-[16px] lg:text-[20px] text-gray-600">
                Direccion:
              </h3>
              <p class="text-[11px] md:text-[16px] text-gray-600 mb-1">Cobija, Pando</p>
              <p class="text-[11px] md:text-[16px] text-yellow-500 font-semibold mb-2">Teléfono: 22681111</p>
              <a href="https://maps.app.goo.gl/BFqEnMPxWx9qJvqi6" class="text-[10px] md:text-[15px] flex items-center text-blue-700 font-bold"
                target="_blank" rel="noopener noreferrer">
                <span class="material-icons mr-1">
                  <MapPinIcon class="md:h-5 md:w-5 h-4 w-4 lg:h-6 lg:w-6 text-green-600" />
                </span> CÓMO LLEGAR
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { MapPinIcon } from '@heroicons/vue/24/solid'

export default {
  name: 'OurDirecctions',
  components: {
    MapPinIcon,
  }
};
</script>