<template>
    <div>
        <div class="mt-[-10px] flex-col px-2 sm:px-16">
            <div class="flex flex-col gap-3 shadow-celeste p-8 rounded-3xl mt-8 mb-12">
                <div class="flex justify-center">
                    <DocumentTextIcon class="text-green-600 w-8 h-8" />
                    <h1 class="text-xl sm:text-2xl font-bold pl-4">MISION</h1>
                </div>
                <p class="text-center text-[12px] sm:text-xl">
                    Servir a la sociedad en transporte aéreo con
                    integridad y gestión de calidad reconocida
                </p>
            </div>
            <div class="flex flex-col gap-3 shadow-celeste p-8 rounded-3xl my-12">
                <div class="flex justify-center">
                    <DocumentTextIcon class="text-green-600 w-8 h-8" />
                    <h1 class="text-xl sm:text-2xl font-bold pl-4">VISION</h1>
                </div>
                <p class="text-center text-[12px] sm:text-xl">
                    Ser el emblema de excelencia en servicio aéreo
                    comprometido con la gente, integrando Bolivia y el
                    mundo
                </p>
            </div>
        </div>
    </div>
</template>
<script>
import { DocumentTextIcon } from '@heroicons/vue/24/solid';

export default {
    name: 'MisionVision',
    components: {
        DocumentTextIcon
    },
}
</script>