<template>
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 p-6">
      <!-- Card 1: Antes de tu viaje -->
      <div class="bg-white p-4 rounded-lg shadow-md hover:shadow-lg transition duration-300">
        <div class="flex items-center mb-4">
          <img src="../assets/centro-ayuda/antes-viaje.webp" alt="Antes de tu viaje" class="w-20 h-20 mr-4" />
          <h3 class="text-lg font-bold">Antes de tu viaje</h3>
        </div>
        <p class="text-gray-600">
          Revisa los documentos y requisitos para entrar a tu destino. Información de comidas, asientos y más.
        </p>
      </div>
  
      <!-- Card 2: Equipaje -->
      <div class="bg-white p-4 rounded-lg shadow-md hover:shadow-lg transition duration-300">
        <div class="flex items-center mb-4">
          <img src="@/assets/centro-ayuda/equipaje.webp" alt="Equipaje" class="w-20 h-20 mr-4" />
          <h3 class="text-lg font-bold">Equipaje</h3>
        </div>
        <p class="text-gray-600">
          Consulta las dimensiones, peso y artículos que puedes llevar en tu equipaje de mano y bodega.
        </p>
      </div>
  
      <!-- Card 3: Tu reserva -->
      <div class="bg-white p-4 rounded-lg shadow-md hover:shadow-lg transition duration-300">
        <div class="flex items-center mb-4">
          <img src="@/assets/centro-ayuda/tu-reserva.webp" alt="Tu reserva" class="w-20 h-20 mr-4" />
          <h3 class="text-lg font-bold">Tu reserva</h3>
        </div>
        <p class="text-gray-600">
          Encuentra temas relacionados con check-in, cambios de vuelo, reembolsos y más.
        </p>
      </div>
  
      <!-- Card 4: Asistencia especial -->
      <div class="bg-white p-4 rounded-lg shadow-md hover:shadow-lg transition duration-300">
        <div class="flex items-center mb-4">
          <img src="@/assets/centro-ayuda/asistencia-especial.webp" alt="Asistencia especial" class="w-20 h-20 mr-4" />
          <h3 class="text-lg font-bold">Asistencia especial</h3>
        </div>
        <p class="text-gray-600">
          Conoce cómo solicitar asistencia especial y volar con equipos médicos.
        </p>
      </div>
  
      <!-- Card 5: Mascotas a bordo -->
      <div class="bg-white p-4 rounded-lg shadow-md hover:shadow-lg transition duration-300">
        <div class="flex items-center mb-4">
          <img src="@/assets/centro-ayuda/mascotas-bordo.webp" alt="Mascotas a bordo" class="w-20 h-20 mr-4" />
          <h3 class="text-lg font-bold">Mascotas a bordo</h3>
        </div>
        <p class="text-gray-600">
          Consulta los documentos y requisitos para volar con tu mascota en cabina o bodega.
        </p>
      </div>
  
      <!-- Card 6: Volar con niños -->
      <div class="bg-white p-4 rounded-lg shadow-md hover:shadow-lg transition duration-300">
        <div class="flex items-center mb-4">
          <img src="@/assets/centro-ayuda/volar-con-ninos.webp" alt="Volar con niños" class="w-20 h-20 mr-4" />
          <h3 class="text-lg font-bold">Volar con niños</h3>
        </div>
        <p class="text-gray-600">
          Conoce los requisitos para volar en estado de embarazo, con niños o bebés.
        </p>
      </div>
    </div>
  </template>

<script>
export default {
    name: 'CentroAyuda',
}
</script>