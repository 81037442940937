<template>
  <div class="extra flex justify-center pt-0">
    <video class="mt-0 md:mt-7 sm:h-44 lg:rounded-2xl md:rounded-2xl" autoplay muted playsinline loop>
      <source src="../assets/ruta-avion.mp4" type="video/mp4">
      Your browser does not support the video tag.
    </video>
  </div>
</template>

<script>
export default {
  name: 'VideoTam'
}
</script>

<style scoped>
.extra {
  @media (max-width: 700px) {
    padding: 0px !important;
  }
}
</style>