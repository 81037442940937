<template>
  <div class="flex flex-col items-center relative">
    <!-- Contenido principal -->
    <div class="flex rounded-2xl border-2 border-blue-700 w-full sm:w-11/12 md:w-3/4 h-auto p-3 sm:p-5 md:p-7 lg:p-9 mx-2 sm:mx-4 md:mx-0 mt-8">
      <div class="flex flex-col w-full">
        <!-- Header con ícono y título -->
        <div class="flex items-center justify-center gap-3 mb-4">
          <component v-if="icon" :is="icon" :class="[iconClass, `h-${iconSize} w-${iconSize}`]" />
          <h2 class="text-white text-xl font-bold">{{ title }}</h2>
        </div>
        
        <!-- Renderizado condicional del contenido -->
        <div class="text-white">
          <template v-if="typeof content === 'string'">
            <!-- Mostrar contenido como HTML si es una cadena de texto -->
            <div v-html="content"></div>
          </template>
          <template v-else>
            <!-- Mostrar contenido como componente si no es una cadena de texto -->
            <component :is="content" v-bind="componentProps"></component>
          </template>
        </div>
      </div>
      <div>
        <!-- Botón de regreso -->
        <button @click="scrollToTop"
          class="p-2 sm:px-4 sm:py-2 bg-blue-700 text-white rounded-lg hover:bg-blue-800 transition-colors duration-300 shadow-md flex items-center">
          <ArrowUpCircleIcon class="h-5 w-5 sm:hidden" />
          <span class="hidden sm:inline">Regresar</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { DocumentArrowUpIcon, UserGroupIcon, ChartBarIcon, DocumentTextIcon, ArrowUpCircleIcon } from '@heroicons/vue/24/solid';

export default {
  name: 'DynamicContent',
  components: {
    DocumentArrowUpIcon,
    UserGroupIcon,
    ChartBarIcon,
    DocumentTextIcon,
    ArrowUpCircleIcon,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    content: {
      type: [String, Object],
      required: true,
    },
    componentProps: {
      type: Object,
      default: () => ({}),
    },
    icon: {
      type: [String, Object],
      default: null,
    },
    iconClass: {
      type: String,
      default: 'text-blue-500',
    },
    iconSize: {
      type: Number,
      default: 6,
    },
  },
  methods: {
    scrollToTop() {
      window.scrollTo({ top: 350, behavior: 'smooth' });
    },
  },
};
</script>