<template>
  <!-- Contenedor principal de la sección -->
  <div :id="id" class="flex flex-col mb-8 scroll-mt-24" ref="proximos">
    <div class="my-2 flex justify-center">
      <h1 class="text-white text-2xl font-bold">Próximas Rutas</h1>
    </div>
    <div class="flex flex-col lg:flex-row justify-around px-8 gap-8 mt-3">
      <div class="lg:w-1/2 h-[300px] sm:h-[450px] md:h-[550px] lg:h-[450px] overflow-hidden rounded-3xl">
        <img src="../assets/proximas/rurre.webp" alt="Cobija" class="h-full w-full object-fill sm:object-cover transform transition duration-300 hover:scale-110">
      </div>
      <div class="lg:w-1/2 h-[300px] sm:h-[450px] md:h-[550px] lg:h-[450px] overflow-hidden rounded-3xl">
        <img src="../assets/proximas/riberalta.webp" alt="Riberalta" class="h-full w-full object-fill sm:object-cover transform transition duration-300 hover:scale-110">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NewDestinations",
  props: {
    id: {
      type: String,
      default: ''
    }
  },
};
</script>